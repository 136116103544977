import ClientTypeEnum from 'shared/enum/ClientTypeEnum';
import React, { Component } from 'react';
import {
  Box,
  ContentHeader,
  ResponsivePie,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Request from 'shared/requests/Request';
import StatusEnum from 'shared/enum/StatusEnum';

class ClientType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientType: {},
      isLoading: true,
      isMounted: true,
    };
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  requestData = () => {
    this.setState({
      isLoading: true,
    });

    const { enqueueSnackbar } = this.props;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/types`;
    Request.get(url)
      .then((result) => {
        const { isMounted } = this.state;
        if (result.data && isMounted) {
          const data = this.formatPieData(result.data);
          this.setState({
            clientType: data,
          });
        } else {
          const message = 'Client type failed to load';
          enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        }
      })
      .catch((error) => {
        const message = 'An error occurred while loading client type';
        enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  formatPieData = (data) => {
    const obj = {};
    if (data['managed service']) {
      obj[ClientTypeEnum.MANAGED_SERVICE] = data['managed service'];
    }
    if (data.agency) {
      obj[ClientTypeEnum.AGENCY] = data.agency;
    }

    if (data.SaaS) {
      obj[ClientTypeEnum.SAAS] = data.SaaS;
    }

    return obj;
  }

  render() {
    const { clientType, isLoading } = this.state;

    const pieData = Object.keys(clientType).map((status) => ({
      id: status,
      label: status,
      value: clientType[status],
    }));

    return (
      <Box>
        <ContentHeader title="Client Type" />
        <ResponsivePie
          size={300}
          loading={isLoading}
          data={pieData}
        />
      </Box>
    );
  }
}

ClientType = withSnackbar(ClientType);

export default ClientType;
