import moment from 'moment';
import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm, initialize } from 'redux-form';
import Skeleton from '@material-ui/lab/Skeleton';
import { required } from 'shared/fieldValidation';
import {
  Box,
  Button,
  capitalizeFirstLetter,
  Columns,
  ContentHeader,
  MenuItem,
  Section,
  ReduxSelect,
  ReduxTextField,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import ClientTypeEnum from 'shared/enum/ClientTypeEnum';
import PermissionEnum from 'shared/enum/PermissionEnum';
import Can from 'shared/components/Can';
import { userCanAccess } from 'shared/permission';
import withClient from '../withClient';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    // this.getUsers();
  }

  // getUsers = () => {
  //   this.setState({ isLoading: true });
  //   const { enqueueSnackbar, match } = this.props;
  //   const clientId = match.params.id;

  //   const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/users`;

  //   Request.get(url, {
  //     params: {
  //       filterKey: 'custom:client_id',
  //       filterValue: clientId,
  //     },
  //   })
  //     .then((result) => {
  //       this.setState({
  //         users: result.data,
  //       });
  //     })
  //     .catch((error) => {
  //       const message = 'Unable to get users.';
  //       enqueueSnackbar(message, { variant: 'error', persist: true });
  //       console.error(message, error);
  //     })
  //     .finally(() => {
  //       this.setState({ isLoading: false });
  //     });
  // }

  onChange = () => {
    // Do nothing
  }

  submit = (values) => {
    const promise = new Promise((resolve, reject) => {
      const { client, enqueueSnackbar } = this.props;
      const {
        name,
        address,
        contact,
        type,
        invoiceMethod,
        managedClientUrl,
        notes,
      } = values;

      const url = `${process.env.REACT_APP_ORCHESTRATOR}/client`;
      Request.put(url, {
        name,
        address,
        contact,
        type,
        invoiceMethod,
        managedClientUrl,
        notes,
        clientId: client.id,
      })
        .then(() => {
          const {
            dispatch, form, reloadClient, reset,
          } = this.props;
          reloadClient();
          dispatch(
            initialize(form, {
              name,
              address,
              contact,
              type,
              invoiceMethod,
              managedClientUrl,
              notes,
            }),
          );
          reset();
          resolve();
        })
        .catch((error) => {
          const message = 'Unable to update details.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, error);
          reject();
        });
    });

    return promise;
  }

  render() {
    const userCanEdit = userCanAccess(PermissionEnum.UPDATE_CLIENTS);
    const { isLoading } = this.state;
    const {
      client, handleSubmit, isClientLoading, pristine, submitting, reset,
    } = this.props;
    const isRetail = client.type === 'retail';
    client.displayType = client.type ? capitalizeFirstLetter(client.type) : '';
    const clientTypes = [
      {
        key: ClientTypeEnum.AGENCY,
        value: 'agency',
      },
      {
        key: ClientTypeEnum.MANAGED_SERVICE,
        value: 'managed service',
      },
      {
        key: ClientTypeEnum.SAAS,
        value: 'SaaS',
      },
    ];

    return (
      <Section>
        <Columns>
          <Columns.Column size={8}>
            {isClientLoading || isLoading ? (
              <Box>
                <div>
                  <Skeleton variant="text" width={300} height={50} style={{ display: 'inline-block' }} />
                  <Skeleton variant="text" width={60} height={50} style={{ float: 'right' }} />
                  <Skeleton variant="text" width={100} height={30} style={{ marginTop: '1rem' }} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                </div>
              </Box>
            ) : (
              <Box>
                <ContentHeader title="Overview">
                  <Button.Group size="small">
                    <Can action={PermissionEnum.UPDATE_CLIENTS}>
                      <Button
                        outlined
                        onClick={reset}
                        disabled={submitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="info"
                        form={`form-${client.id}`}
                        disabled={pristine}
                        loading={submitting}
                      >
                        Save
                      </Button>
                    </Can>
                  </Button.Group>
                </ContentHeader>

                <form
                  id={`form-${client.id}`}
                  onSubmit={handleSubmit(this.submit)}
                >
                  <div>
                    <ReduxField
                      name="name"
                      label="Name"
                      component={ReduxTextField}
                      validate={[required]}
                      margin="normal"
                      fullWidth
                      disabled={!userCanEdit}
                    />
                  </div>
                  <div>
                    <ReduxField
                      name="address"
                      label="Address"
                      component={ReduxTextField}
                      validate={[required]}
                      margin="normal"
                      fullWidth
                      disabled={!userCanEdit}
                    />
                  </div>
                  <div style={{ margin: isRetail ? '0 0 0 0' : '16px 0 8px 0' }}>
                    <ReduxField
                      name={isRetail ? 'displayType' : 'type'}
                      label="Type"
                      component={isRetail ? ReduxTextField : ReduxSelect}
                      className={isRetail ? '' : 'MuiFormControl-marginNormal'}
                      margin={isRetail ? 'normal' : ''}
                      fullWidth
                      disabled={!userCanEdit || isRetail}
                    >
                      {!isRetail && clientTypes.map((clientType) => (
                        <MenuItem key={clientType.key} value={clientType.value}>
                          {clientType.key}
                        </MenuItem>
                      ))}
                    </ReduxField>
                  </div>
                  {/* <div style={{ margin: isRetail ? '16px 0 8px 0' : '24px 0 8px 0' }}>
                    <ReduxField
                      name="contact"
                      label="Primary Contact"
                      component={ReduxSelect}
                      className="MuiFormControl-marginNormal"
                      fullWidth
                      disabled={!userCanEdit}
                    >
                      {users.map((user) => (
                        <MenuItem key={user.Username} value={user.Username}>
                          {user.Username}
                        </MenuItem>
                      ))}
                    </ReduxField>
                  </div> */}
                  {/* <div>
                    <ReduxField
                      name="managedClientUrl"
                      label="Managed Client URL"
                      component={ReduxTextField}
                      margin="normal"
                      fullWidth
                      disabled={!userCanEdit}
                    />
                  </div>
                  <div>
                    <ReduxField
                      name="notes"
                      label="Notes"
                      component={ReduxTextField}
                      margin="normal"
                      fullWidth
                      multiline
                      rowsMax="10"
                      disabled={!userCanEdit}
                    />
                  </div> */}
                </form>
              </Box>
            )}
          </Columns.Column>
          <Columns.Column>
            {isClientLoading || isLoading ? (
              <Box>
                <div>
                  <Skeleton variant="text" width={150} height={50} style={{ display: 'inline-block' }} />
                  <Skeleton variant="text" width={100} height={30} style={{ marginTop: '1rem' }} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                  <Skeleton variant="text" width={100} height={30} />
                  <Skeleton variant="text" height={50} style={{ width: '100%' }} />
                </div>
              </Box>
            ) : (
              <Box>
                <ContentHeader title="Details" />
                <div>
                  <TextField
                    label="Status"
                    defaultValue={client.status || '-'}
                    margin="normal"
                    fullWidth
                    disabled
                  />
                  {/* <TextField
                    label="Agreements Accepted"
                    defaultValue={
                      client.acceptedAt ? moment.unix(client.acceptedAt / 1000).format('MM.DD.YYYY') : 'Not Accepted'
                    }
                    margin="normal"
                    fullWidth
                    disabled
                  /> */}
                  <TextField
                    label="Created On"
                    defaultValue={moment.unix(client.createdAt / 1000).format('MM.DD.YYYY')}
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </div>
              </Box>
            )}
          </Columns.Column>
        </Columns>
      </Section>
    );
  }
}

Dashboard = reduxForm({
  form: 'client-edit-details',
  enableReinitialize: true,
})(Dashboard);

Dashboard = withClient(Dashboard);

Dashboard = withSnackbar(Dashboard);

const mapStateToProps = (state) => ({
  client: state.app.client,
  initialValues: state.app.client,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
