import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Section } from '@rlx-pros/react-foundation';

const ApplicationSkeleton = () => (
  <Section>
    <Box className="loading">
      <Skeleton variant="text" width={150} height={36} />
      <Skeleton variant="text" width={350} height={24} style={{ marginBottom: 16 }} />
      <Skeleton variant="text" width={500} height={24} />
    </Box>
  </Section>
);

export default ApplicationSkeleton;
