import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { setClient } from 'redux/Wizard';
import { required, notAmazonMarketplaceId } from 'shared/fieldValidation';
import {
  Button,
  Level,
  Section,
  ReduxTextField,
} from '@rlx-pros/react-foundation';

class StepClient extends Component {
  next = (data) => {
    const { nextStep, setClient } = this.props;
    setClient(data);
    nextStep();
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Section>
          <form id="onboarding-client" onSubmit={handleSubmit(this.next)}>
            <ReduxField
              name="name"
              label="Name"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
            <ReduxField
              name="address"
              label="Address"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
            <ReduxField
              name="merchant_id"
              label="Merchant Id"
              component={ReduxTextField}
              validate={[required, notAmazonMarketplaceId]}
              margin="normal"
              fullWidth
              helperText="Note: This cannot be updated, please double check"
            />
          </form>
        </Section>

        <Section>
          <Level>
            <Level.Side align="left" />
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-client">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setClient: (client) => dispatch(setClient(client)),
});

export default connect(
  null,
  mapDispatchToProps,
)(reduxForm({
  form: 'onboarding-client',
})(StepClient));
