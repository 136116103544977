/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';

const ToggleSwitch = ({
  input, label,
}) => (
  <div className="field">
    <input {...input} id={input.name} type="checkbox" className="switch is-rounded" />
    <label htmlFor={input.name} className="label">{label}</label>
  </div>
);

export default ToggleSwitch;
