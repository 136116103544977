import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ContentHeader,
  Section,
  StepWizard,
} from '@rlx-pros/react-foundation';
import Can from 'shared/components/Can';
import PermissionEnum from 'shared/enum/PermissionEnum';
// Steps
import StepNavigation from './create/StepNavigation';
import StepUser from './create/StepUser';
import StepReview from './create/StepReview';

const Create = () => (
  <Can action={PermissionEnum.CREATE_CLIENT_USER}>
    <div>
      <ContentHeader title="Create User" size="medium" color="white">
        <Button.Group size="small">
          <Link to="/users" className="button is-rounded is-outlined is-danger">Cancel</Link>
        </Button.Group>
      </ContentHeader>

      <Section>
        <Box>
          <StepWizard nav={<StepNavigation />} initialStep="1">
            <StepUser />
            <StepReview />
          </StepWizard>
        </Box>
      </Section>
    </div>
  </Can>
);

export default Create;
