import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ReduxThunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from 'redux/App';
import Wizard from 'redux/Wizard';

const rootReducer = combineReducers({
  app: App,
  wizard: Wizard,
  form: formReducer,
});

const middleware = [ReduxThunk];

if (process.env.REACT_APP_DEBUG === 'true') {
  const logger = createLogger({
    // Collapse actions by default unless they caused an error.
    collapsed: (getState, action, logEntry) => !logEntry.error,

    // If the logs get annoying we can filter them out by action.type.
    // predicate: (getState, action) => action.type !== AUTH_REMOVE_TOKEN
  });

  middleware.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['form'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware)),
);
export const persistor = persistStore(store);
