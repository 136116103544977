import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { setUsers, clearUsers } from 'redux/Wizard';
import {
  Button,
  Level,
  ReduxTextField,
  Section,
  Switch,
} from '@rlx-pros/react-foundation';
import {
  email,
  required,
} from 'shared/fieldValidation';

class StepUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addUsers: false,
    };
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  next = (data) => {
    const { nextStep, setUsers } = this.props;
    const { addUsers } = this.state;

    if (addUsers) {
      setUsers(data);
    }
    nextStep();
  }

  onAddUsers = (event) => {
    const { reset, clearUsers } = this.props;
    const isAddingUsers = event.target && event.target.checked;
    if (!isAddingUsers) {
      clearUsers();
      reset();
    }

    this.setState({
      addUsers: isAddingUsers,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { addUsers } = this.state;

    return (
      <div>
        <Section>
          <div>
            Do you want to add a user to this client?
            <Switch onChange={this.onAddUsers} />
          </div>
          <form id="onboarding-users" onSubmit={handleSubmit(this.next)}>
            { addUsers ? (
              <div>
                <ReduxField
                  name="email"
                  label="Email"
                  component={ReduxTextField}
                  validate={[required, email]}
                  margin="normal"
                  fullWidth
                />
                <ReduxField
                  name="first_name"
                  label="First Name"
                  component={ReduxTextField}
                  validate={[required]}
                  margin="normal"
                  fullWidth
                />
                <ReduxField
                  name="last_name"
                  label="Last Name"
                  component={ReduxTextField}
                  validate={[required]}
                  margin="normal"
                  fullWidth
                />
              </div>
            ) : (
              <div />
            )}
          </form>
        </Section>

        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-users">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const getUser = async (email) => {
  const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/user-exists`;
  const response = await Request.get(url, {
    params: {
      Username: email,
    },
  });

  return response.data;
};

const asyncValidate = async (values) => {
  if (values && values.email) {
    const userResult = await getUser(values.email)
      .then((data) => {
        if (data !== false) {
          const err = { email: 'That email is taken' };

          throw err;
        }
      });

    return userResult;
  }

  return new Promise((resolve) => { resolve(); });
};

const mapDispatchToProps = (dispatch) => ({
  setUsers: (client) => dispatch(setUsers(client)),
  clearUsers: () => dispatch(clearUsers()),
});

export default connect(
  null,
  mapDispatchToProps,
)(reduxForm({
  form: 'onboarding-users',
  asyncValidate,
  asyncBlurFields: ['email'],
})(StepUsers));
