/* eslint-disable no-undef */
import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ContentHeader,
  Navtabs,
  NotFound,
  Tooltip,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { OK } from 'http-status-codes';
import { setClient, clearClient } from 'redux/App';
import { getAuthorizedClientIds, updateAuthorizedClient, updateAuthorizedRetailClient } from 'shared/requests/authorizedClient';
import ClientStatusEnum from 'shared/enum/ClientStatusEnum';
import Can from 'shared/components/Can';
import PermissionEnum from 'shared/enum/PermissionEnum';
import ClientContext from './ClientContext';
import Dashboard from './client/Dashboard';
import Applications from './client/Applications';
import Users from './client/Users';

class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      loading: true,
      isCurrentAuthorizedUser: false,
    };
  }

  componentDidMount() {
    const { enqueueSnackbar, match } = this.props;
    const clientId = match.params.id;

    getAuthorizedClientIds()
      .then((authorizedClientIds) => {
        if (!authorizedClientIds.authorizedAs) {
          console.log('No authorized client id. Got a value of: ', authorizedClientIds);
          enqueueSnackbar('Unable to retrieve the client user for this Admin user. Cannot login as client.', { variant: 'warning' });
        }

        this.setState({
          isCurrentAuthorizedUser: clientId === authorizedClientIds.authorizedAs || clientId === authorizedClientIds.retailAuthorizedAs,
        });
      })
      .then(this.getClient)
      .catch((error) => {
        console.error('There was an error getting an authorized client id.', error);
      });
  }

  componentWillUnmount() {
    const { clearClient } = this.props;
    clearClient();
  }

  disableClient = () => {
    this.updateClient('PUT', 'disable');
  }

  enableClient = () => {
    this.updateClient('POST', 'enable');
  }

  updateClient = (method, status) => {
    const { match, enqueueSnackbar } = this.props;
    const clientId = match.params.id;

    this.setState({ updating: true });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/status`;
    Request({
      method,
      url,
      data: {
        clientId,
      },
    })
      .then((result) => {
        const { data } = result;
        if (!data || data.statusCode !== OK) {
          throw new Error('An error was returned when updating the client status');
        }
        this.getClient();
      })
      .catch((error) => {
        const message = `Unable to ${status} client.`;
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({ updating: false });
      });
  }

  getClient = () => {
    const { enqueueSnackbar, match, setClient } = this.props;
    const clientId = match.params.id;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client`;

    this.setState({ loading: true });

    Request.get(url, {
      params: {
        clientId,
      },
    })
      .then((result) => {
        setClient(result.data[0]);
      })
      .catch((error) => {
        const message = 'Unable to load client.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  invokeUpdateAuthorizedClient = async () => {
    const { auth, enqueueSnackbar, match } = this.props;

    const clientId = match.params.id;

    updateAuthorizedClient(auth.username, clientId)
      .then(async (result) => {
        if (result.data === 'Success') {
          // Automatically login this user in the platform client portal.
          window.open(
            `${process.env.REACT_APP_CLIENT_AUTHENTICATION_DOMAIN}/refresh?redirectUrl=${process.env.REACT_APP_CLIENT_PORTAL_DOMAIN}`,
            '_blank',
          );
          this.setState({
            isCurrentAuthorizedUser: true,
          });
        } else {
          const message = 'Unable to authorize as client.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to authorize as client.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      });
  }

  invokeUpdateAuthorizedRetailClient = async () => {
    const { auth, enqueueSnackbar, match } = this.props;

    const clientId = match.params.id;

    updateAuthorizedRetailClient(auth.username, clientId)
      .then(async (result) => {
        if (result.data === 'Success') {
          // Automatically login this user in the Retail client portal.
          window.open(
            `${process.env.REACT_APP_CLIENT_AUTHENTICATION_DOMAIN}/refresh?redirectUrl=${process.env.REACT_APP_RETAIL_CLIENT_PORTAL_DOMAIN}`,
            '_blank',
          );
          this.setState({
            isCurrentAuthorizedUser: true,
          });
        } else {
          const message = 'Unable to authorize as client.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to authorize as client.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      });
  }

  openPlatformForAuthorizedClient = () => {
    window.open(
      `${process.env.REACT_APP_CLIENT_PORTAL_DOMAIN}`,
      '_blank',
    );
  }

  openPlatformForAuthorizedRetailClient = () => {
    window.open(
      `${process.env.REACT_APP_RETAIL_CLIENT_PORTAL_DOMAIN}`,
      '_blank',
    );
  }

  render() {
    const { client, match } = this.props;
    const { updating, loading, isCurrentAuthorizedUser } = this.state;
    const { params, path, url } = match;
    const { type } = params;

    const retailLinks = [
      {
        title: 'Dashboard',
        href: `${url}/dashboard`,
      },
      {
        title: 'Users',
        href: `${url}/users`,
      },
    ];

    const nonRetailLinks = [
      {
        title: 'Dashboard',
        href: `${url}/dashboard`,
      },
      {
        title: 'Applications',
        href: `${url}/applications`,
      },
      {
        title: 'Users',
        href: `${url}/users`,
      },
    ];

    return (
      <div>
        <ContentHeader title={client.name} subtitle={client.id} size="medium" color="white">
          <Button.Group size="small">
            <Can action={PermissionEnum.UPDATE_CLIENTS}>
              {
                  (client.status !== ClientStatusEnum.DEACTIVATED) ? (
                    <Button
                      color="danger"
                      outlined
                      rounded
                      loading={updating}
                      disabled={loading}
                      onClick={this.disableClient}
                    >
                      Disable
                    </Button>
                  ) : (
                    <Button
                      rounded
                      loading={updating}
                      disabled={loading}
                      onClick={this.enableClient}
                    >
                      Enable
                    </Button>
                  )
                }
            </Can>
            <Can action={PermissionEnum.AUTHORIZE_CLIENT_ACCESS}>
              {
                  (isCurrentAuthorizedUser) ? (
                    <Tooltip title="Open Platform">
                      <Button onClick={type === 'retail' ? this.openPlatformForAuthorizedRetailClient : this.openPlatformForAuthorizedClient} rounded>Open Platform</Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Login as this client">
                      <Button onClick={type === 'retail' ? this.invokeUpdateAuthorizedRetailClient : this.invokeUpdateAuthorizedClient} rounded>Login as Client</Button>
                    </Tooltip>
                  )
                }
            </Can>
          </Button.Group>
        </ContentHeader>
        <Navtabs links={type === 'retail' ? retailLinks : nonRetailLinks} />

        <ClientContext.Provider value={{
          client,
          loading,
          requestData: this.getClient,
        }}
        >
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/dashboard`} />
            </Route>
            <Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/applications`} component={Applications} />
            {/* <Can action={PermissionEnum.LIST_CLIENT_USERS}> */}
            <Route exact path={`${path}/users`} component={Users} />
            {/* </Can> */}
            <Route component={NotFound} />
          </Switch>
        </ClientContext.Provider>
      </div>
    );
  }
}

Client = withSnackbar(Client);

const mapStateToProps = (state) => ({
  client: state.app.client,
  auth: state.app.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setClient: (client) => dispatch(setClient(client)),
  clearClient: () => dispatch(clearClient()),
});

Client = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Client);

export default Client;
