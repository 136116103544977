import React, { Component } from 'react';
import { Button, Modal } from '@rlx-pros/react-foundation';

class PasswordResetModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isProcessing: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.isOpen,
      isProcessing: nextProps.isProcessing,
    };
  }

  close = () => {
    const { closeCallback } = this.props;
    closeCallback();
  }

  resetPassword = () => {
    const { resetPasswordCallback } = this.props;
    resetPasswordCallback();
  }

  render() {
    const { isOpen, isProcessing } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        contentLabel="Force Reset User Password?"
      >
        <p>This action will force this user to reset their password. Are you sure this is what you want to do?</p>
        <br />
        <Button.Group position="right">
          <Button outlined disabled={isProcessing} onClick={this.close}>Cancel</Button>
          <Button color="danger" loading={isProcessing} onClick={this.resetPassword}>Reset Password</Button>
        </Button.Group>
      </Modal>
    );
  }
}

export default PasswordResetModal;
