// -----------------------------------------------------------------------------
// Reducer
// -----------------------------------------------------------------------------
const actions = {};
const initialState = {
  client: {},
  user: {},
  users: {},
  permissions: {},
  applications: {},
};

const Wizard = (state = initialState, action) => {
  const handler = actions[action.type];
  return handler ? handler(state, action) : state;
};

export default Wizard;

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

/*
 * Client
 */
export const SET_CLIENT = 'WIZARD_SET_CLIENT';
export const CLEAR_CLIENT = 'WIZARD_CLEAR_CLIENT';

/*
 * Users
 */
export const SET_USERS = 'WIZARD_SET_USERS';
export const CLEAR_USERS = 'WIZARD_CLEAR_USERS';

/*
 * User
 */
export const SET_USER = 'WIZARD_SET_USER';
export const CLEAR_USER = 'WIZARD_CLEAR_USER';

/*
 * Permissions
 */
export const SET_PERMISSIONS = 'WIZARD_SET_PERMISSIONS';
export const CLEAR_PERMISSIONS = 'WIZARD_CLEAR_PERMISSIONS';

/*
 * Applications
 */
export const SET_APPLICATIONS = 'WIZARD_SET_APPLICATIONS';
export const CLEAR_APPLICATIONS = 'WIZARD_CLEAR_APPLICATIONS';

// -----------------------------------------------------------------------------
// Action Creators
// -----------------------------------------------------------------------------

/*
 * Client
 */
export const setClient = (client) => ({
  type: SET_CLIENT,
  payload: client,
});

export const clearClient = () => ({
  type: CLEAR_CLIENT,
});

/*
 * Users
 */
export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});

export const clearUsers = () => ({
  type: CLEAR_USERS,
});

/*
 * User
 */
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

/*
 * Permissions
 */
export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const clearPermissions = () => ({
  type: CLEAR_PERMISSIONS,
});

/*
 * Applications
 */
export const setApplications = (applications) => ({
  type: SET_APPLICATIONS,
  payload: applications,
});

export const clearApplications = () => ({
  type: CLEAR_APPLICATIONS,
});

// -----------------------------------------------------------------------------
// Actions Handlers
// -----------------------------------------------------------------------------
/*
 * Client
 */
actions[SET_CLIENT] = function (state, action) {
  return { ...state, client: action.payload };
};

actions[CLEAR_CLIENT] = function (state) {
  return { ...state, client: {} };
};

/*
 * Users
 */
actions[SET_USERS] = function (state, action) {
  return { ...state, users: action.payload };
};

actions[CLEAR_USERS] = function (state) {
  return { ...state, users: {} };
};

/*
 * User
 */
actions[SET_USER] = function (state, action) {
  return {
    ...state,
    user: action.payload,
  };
};

actions[CLEAR_USER] = function (state) {
  return {
    ...state,
    user: {},
  };
};

/*
 * Permissions
 */
actions[SET_PERMISSIONS] = function (state, action) {
  return {
    ...state,
    permissions: action.payload,
  };
};

actions[CLEAR_PERMISSIONS] = function (state) {
  return {
    ...state,
    permissions: {},
  };
};

/*
 * Applications
 */
actions[SET_APPLICATIONS] = function (state, action) {
  return { ...state, applications: action.payload };
};

actions[CLEAR_APPLICATIONS] = function (state) {
  return { ...state, applications: {} };
};
