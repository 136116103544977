import Can from 'shared/components/Can';
import httpStatusCodes from 'http-status-codes';
import moment from 'moment';
import PermissionEnum from 'shared/enum/PermissionEnum';
import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm, initialize } from 'redux-form';
import {
  Box,
  Button,
  Columns,
  ContentHeader,
  Drawer,
  MenuItem,
  ReduxSelect,
  ReduxTextField,
  Section,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { required } from 'shared/fieldValidation';
import { userCanAccess } from 'shared/permission';
import Skeleton from '@material-ui/lab/Skeleton';
import RoleTable from './dashboard/RoleTable';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRoleTableDrawerOpen: false,
    };
  }

  onChange = () => {
    // Do nothing
  }

  handleRoleTable = (event) => {
    event.preventDefault();
    this.toggleRoleTableDrawer();
  }

  toggleRoleTableDrawer = () => {
    const { isRoleTableDrawerOpen } = this.state;

    this.setState({
      isRoleTableDrawerOpen: !isRoleTableDrawerOpen,
    });
  }

  submit = (values) => {
    const promise = new Promise((resolve, reject) => {
      const { user, enqueueSnackbar } = this.props;

      const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user`;
      Request.put(url, {
        Username: user.Username,
        UserAttributes: [
          {
            Name: 'given_name',
            Value: values.given_name,
          },
          {
            Name: 'family_name',
            Value: values.family_name,
          },
        ],
        GroupName: values.groupName,
      })
        .then((result) => {
          if (result.data.status !== httpStatusCodes.OK) {
            enqueueSnackbar(result.data.message, { variant: 'error', persist: true });
            console.error(result);
            reject();
          } else {
            const { dispatch, form, getUser } = this.props;
            getUser();
            dispatch(initialize(form, {
              given_name: values.given_name,
              family_name: values.family_name,
              groupName: values.groupName,
            }));
            resolve();
          }
        })
        .catch((error) => {
          const message = 'Unable to update user.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, error);
          reject();
        });
    });

    return promise;
  }

  render() {
    const userCanEdit = userCanAccess(PermissionEnum.UPDATE_ADMIN_USERS);
    const {
      user, handleSubmit, pristine, submitting, reset, permissionMap, loading,
    } = this.props;
    const { isRoleTableDrawerOpen } = this.state;

    const roles = Object.keys(permissionMap);
    const userRoles = [];
    roles.forEach((obj, index) => {
      userRoles[index] = { key: `admin:${obj}`, value: `admin:${obj}` };
    });

    return (
      <div>
        {loading ? (
          <Section>
            <Columns>
              <Columns.Column size={8}>
                <Box>
                  <Skeleton variant="text" width={200} height={50} style={{ marginBottom: 24 }} />
                  <Skeleton variant="text" width="100%" height={30} style={{ marginBottom: 16 }} />
                  <Skeleton variant="text" width="100%" height={30} style={{ marginBottom: 16 }} />
                  <Skeleton variant="text" width="100%" height={30} style={{ marginBottom: 16 }} />
                </Box>
              </Columns.Column>
              <Columns.Column>
                <Box>
                  <Skeleton variant="text" width={200} height={50} style={{ marginBottom: 24 }} />
                  <Skeleton variant="text" width="100%" height={30} style={{ marginBottom: 16 }} />
                  <Skeleton variant="text" width="100%" height={30} style={{ marginBottom: 16 }} />
                </Box>
              </Columns.Column>
            </Columns>
          </Section>
        ) : (
          <Section>
            <Columns>
              <Columns.Column size={8}>
                <Box>
                  <ContentHeader title="Overview">
                    <Button.Group size="small">
                      <Can action={PermissionEnum.UPDATE_ADMIN_USERS}>
                        <div>
                          <Button
                            onClick={reset}
                            disabled={submitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="info"
                            form="form-user-update"
                            disabled={pristine}
                            loading={submitting}
                          >
                            Save
                          </Button>
                        </div>
                      </Can>
                    </Button.Group>
                  </ContentHeader>
                  <form id="form-user-update" onSubmit={handleSubmit(this.submit)}>
                    <ReduxField
                      name="given_name"
                      label="Given Name"
                      component={ReduxTextField}
                      validate={[required]}
                      disabled={!userCanEdit}
                      margin="normal"
                      fullWidth
                    />
                    <ReduxField
                      name="family_name"
                      label="Family Name"
                      component={ReduxTextField}
                      validate={[required]}
                      disabled={!userCanEdit}
                      margin="normal"
                      fullWidth
                    />
                    <div style={{ margin: '16px 0 8px 0' }}>
                      <ReduxField
                        name="groupName"
                        label="User Role"
                        component={ReduxSelect}
                        className="MuiFormControl-marginNormal"
                        fullWidth
                        disabled={!userCanEdit}
                        helperText={
                          (
                            <span>
                              <a href="/" onClick={this.handleRoleTable}>Read more </a>
                              about role permissions
                            </span>
                          )
                        }
                      >
                        {userRoles.map((role) => (
                          <MenuItem key={role.key} value={role.value}>
                            {role.value}
                          </MenuItem>
                        ))}
                      </ReduxField>
                    </div>
                  </form>
                </Box>
              </Columns.Column>
              <Columns.Column>
                <Box>
                  <ContentHeader title="Details" />
                  <div>
                    <TextField
                      label="Enabled"
                      value={user.Enabled ? 'Yes' : 'No'}
                      onChange={this.onChange}
                      margin="normal"
                      fullWidth
                      disabled
                    />
                    <TextField
                      label="Created At"
                      value={moment(user.UserCreateDate).format('MM.DD.YYYY')}
                      onChange={this.onChange}
                      disabled
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </Box>
              </Columns.Column>
            </Columns>
            <Drawer
              anchor="right"
              open={isRoleTableDrawerOpen}
              onClose={this.toggleRoleTableDrawer}
            >
              <Drawer.Bar close={this.toggleRoleTableDrawer} />
              <RoleTable
                toggleRoleTableDrawer={this.toggleRoleTableDrawer}
              />
            </Drawer>
          </Section>
        )}
      </div>
    );
  }
}

Dashboard = reduxForm({
  form: 'form-update-user',
  enableReinitialize: true,
})(Dashboard);

Dashboard = withSnackbar(Dashboard);

const mapStateToProps = (state) => ({
  user: state.app.user,
  initialValues: { ...state.app.user.UserMap, ...{ groupName: state.app.user.groupName } },
  permissionMap: state.app.permissionMap,
});

Dashboard = connect(
  mapStateToProps,
)(Dashboard);

export default Dashboard;
