import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Box,
  Button,
  Grid,
  Level,
  Section,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import PartnerCampaignContext from './PartnerCampaignContext';
import PartnerCampaignsTable from './tables/PartnerCampaignsTable';

class StepPartnerCampaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unAddedData: [],
      addedData: [],
      selectedUnAddedRows: [],
      selectedAddedRows: [],
      loading: false,
      loadedPrefixes: [],
    };

    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.handleIsBrandedChange = this.handleIsBrandedChange.bind(this);
  }

  componentDidUpdate() {
    this.requestData();
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  next = () => {
    const { nextStep } = this.props;
    nextStep();
  }

  updateUnaddedRowsState = (selectedRows) => {
    this.setState({ selectedUnAddedRows: selectedRows });
  }

  updateAddedRowsState = (selectedRows) => {
    this.setState({ selectedAddedRows: selectedRows });
  }

  /* eslint-disable no-param-reassign */
  /* eslint-disable no-return-assign */
  add() {
    let { unAddedData, addedData } = this.state;
    const { selectedUnAddedRows } = this.state;
    const { updatePartnerCampaigns } = this.props;

    const unAdded = unAddedData.filter((eachUnaddedData) => {
      const selectedRow = selectedUnAddedRows.find((eachSelectedUnaddedRow) => eachSelectedUnaddedRow.campaignId === eachUnaddedData.campaignId);
      return !selectedRow;
    });
    let added = unAddedData.filter((eachUnaddedData) => {
      const selectedRow = selectedUnAddedRows.find((eachSelectedUnaddedRow) => eachSelectedUnaddedRow.campaignId === eachUnaddedData.campaignId);
      if (selectedRow) {
        selectedRow.checked = false;
      }
      return selectedRow;
    });
    added = added.concat(addedData);
    added.sort((a, b) => ((a.campaignId > b.campaignId) ? 1 : ((b.campaignId > a.campaignId) ? -1 : 0)));
    unAddedData = unAddedData.map((eachUnAddedData) => eachUnAddedData.tableData.checked = false);
    addedData = addedData.map((eachAddedData) => eachAddedData.tableData.checked = false);

    this.setState({ unAddedData: unAdded, addedData: added, selectedUnAddedRows: [] });
    updatePartnerCampaigns(added);
  }

  remove() {
    let { unAddedData, addedData } = this.state;
    const { selectedAddedRows } = this.state;
    const { updatePartnerCampaigns } = this.props;

    const added = addedData.filter((eachAddedData) => {
      const selectedRow = selectedAddedRows.find((eachSelectedAddedRow) => eachSelectedAddedRow.campaignId === eachAddedData.campaignId);
      return !selectedRow;
    });
    let unAdded = addedData.filter((eachAddedData) => {
      const selectedRow = selectedAddedRows.find((eachSelectedAddedRow) => eachSelectedAddedRow.campaignId === eachAddedData.campaignId);
      if (selectedRow) {
        selectedRow.checked = false;
      }
      return selectedRow;
    });
    unAdded = unAdded.concat(unAddedData);
    unAdded.sort((a, b) => ((a.campaignId > b.campaignId) ? 1 : ((b.campaignId > a.campaignId) ? -1 : 0)));
    unAddedData = unAddedData.map((eachUnAddedData) => eachUnAddedData.tableData.checked = false);
    addedData = addedData.map((eachAddedData) => eachAddedData.tableData.checked = false);

    this.setState({ unAddedData: unAdded, addedData: added, selectedAddedRows: [] });
    updatePartnerCampaigns(added);
  }

  requestData() {
    const { prefixes, enqueueSnackbar } = this.props;
    const { loadedPrefixes, addedData } = this.state;
    if (prefixes === loadedPrefixes) return;
    this.setState({ loading: true, loadedPrefixes: prefixes });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/ad-manager-service/campaigns-by-prefix`;

    Request.get(url, { params: prefixes })
      .then((result) => {
        if (result.data) {
          const unaddedData = result.data.filter((eachData) => !addedData.find((eachAddedData) => eachAddedData.campaignId === eachData.campaignId)).map(((eachData) => {
            eachData.isBranded = false;
            eachData.startDate = moment(eachData.startDate).format('MM/DD/YY');
            return eachData;
          }));
          this.setState({ unAddedData: unaddedData });
        }
      })
      .catch((error) => {
        const message = 'Unable to load campaigns.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleIsBrandedChange(campaignId, checked) {
    const { addedData, unAddedData } = this.state;
    const newAddedData = addedData.map((eachAddedData) => {
      if (eachAddedData.campaignId === campaignId) {
        eachAddedData.isBranded = !checked;
      }
      return eachAddedData;
    });
    const newUnAddedData = unAddedData.map((eachUnAddedData) => {
      if (eachUnAddedData.campaignId === campaignId) {
        eachUnAddedData.isBranded = !checked;
      }
      return eachUnAddedData;
    });
    this.setState({ addedData: newAddedData, unAddedData: newUnAddedData });
  }

  render() {
    const {
      unAddedData,
      addedData,
      loading,
      selectedUnAddedRows,
      selectedAddedRows,
    } = this.state;
    const { handleSubmit } = this.props;

    return (
      <div>
        <form id="onboarding-partner-campaigns" onSubmit={handleSubmit(this.next)}>
          <Grid container>
            <Grid item xs={5}>
              <Box>
                <PartnerCampaignContext.Provider value={{
                  loading,
                  data: unAddedData,
                  updateSelectedRows: this.updateUnaddedRowsState,
                  handleIsBrandedChange: this.handleIsBrandedChange,
                }}
                >
                  <PartnerCampaignsTable />
                </PartnerCampaignContext.Provider>
              </Box>
              <Section>
                <div style={{ float: 'right' }}>
                  <Button type="button" outlined color={!selectedUnAddedRows.length ? '' : 'primary'} onClick={this.add} disabled={!selectedUnAddedRows.length}>Add</Button>
                </div>
              </Section>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Box>
                <PartnerCampaignContext.Provider value={{
                  loading,
                  data: addedData,
                  updateSelectedRows: this.updateAddedRowsState,
                  handleIsBrandedChange: this.handleIsBrandedChange,
                }}
                >
                  <PartnerCampaignsTable />
                </PartnerCampaignContext.Provider>
              </Box>
              <Section>
                <div style={{ float: 'right' }}>
                  <Button type="button" outlined color={!selectedAddedRows.length ? '' : 'primary'} onClick={this.remove} disabled={!selectedAddedRows.length}>Remove</Button>
                </div>
              </Section>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </form>
        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-partner-campaigns">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applications: state.wizard.applications,
  client: state.wizard.client,
  users: state.wizard.users,
});

StepPartnerCampaigns = withSnackbar(StepPartnerCampaigns);

export default connect(
  mapStateToProps,
  null,
)(reduxForm({
  form: 'onboarding-partner-campaigns',
})(StepPartnerCampaigns));
