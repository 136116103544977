import { permission } from '@rlx-pros/react-foundation';
import { store } from 'store';

/**
 * Parse the application roles from the authenticated users groups.
 *
 * @param {*} auth
 */
export const getRolesFromAuth = (auth) => {
  const cognitoGroups = auth.signInUserSession?.idToken?.payload['cognito:groups'] || [];
  const roles = permission.parseRoles(cognitoGroups, 'admin');

  return roles;
};

/**
 * Convert the react-foundation Can component to a boolean value.
 *
 * @param string action
 */
export const userCanAccess = (action) => {
  const state = store.getState();
  const { permissionMap } = state.app;
  const roles = getRolesFromAuth(state.app.auth);

  return permission.isAllowed(action, permissionMap, roles);
};
