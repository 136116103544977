import React, { Component } from 'react';
import {
  Box,
  ContentHeader,
  ResponsiveRadar,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Request from 'shared/requests/Request';
import StatusEnum from 'shared/enum/StatusEnum';

class ApplicationSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isMounted: true,
      data: [],
    };
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  requestData() {
    this.setState({
      isLoading: true,
    });

    const { enqueueSnackbar } = this.props;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/application-clients`;
    Request.get(url)
      .then((result) => {
        const { isMounted } = this.state;
        if (result.data && isMounted) {
          const data = [];

          result.data.forEach((obj) => {
            data.push({
              application: obj.name,
              clients: obj.clientCount,
            });
          });

          this.setState({
            data,
          });
        } else {
          const message = 'Application subscriptions failed to load';
          enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        }
      })
      .catch((error) => {
        const message = 'An error occurred while loading application subscriptions';
        enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, data } = this.state;

    return (
      <Box>
        <ContentHeader title="Application Subscriptions" />
        <ResponsiveRadar
          size={300}
          loading={isLoading}
          data={data}
          indexBy="application"
          keys={['clients']}
        />
      </Box>
    );
  }
}

ApplicationSubscriptions = withSnackbar(ApplicationSubscriptions);

export default ApplicationSubscriptions;
