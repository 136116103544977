import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  ContentHeader,
  Section,
} from '@rlx-pros/react-foundation';
import PermissionEnum from 'shared/enum/PermissionEnum';
import Can from 'shared/components/Can';

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: props.application.enabled,
      updating: false,
    };
  }

  grant = () => {
    this.setState({
      updating: true,
    });
    const { application, client } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/application`;
    Request.post(url, {
      clientId: client.id,
      applicationId: application.id,
    })
      .then((result) => {
        if (result.data === true) {
          this.setState({ enabled: true });
        }
      })
      .catch((error) => {
        console.error('Client Application Grant: ', error);
      })
      .finally(() => {
        this.setState({
          updating: false,
        });
      });
  }

  revoke = () => {
    this.setState({
      updating: true,
    });
    const { application, client } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/application`;
    Request({
      method: 'delete',
      url,
      data: {
        clientId: client.id,
        applicationId: application.id,
      },
    })
      .then((result) => {
        if (result.data === true) {
          this.setState({ enabled: false });
        }
      })
      .catch((error) => {
        console.error('Client Application Revoke: ', error);
      })
      .finally(() => {
        this.setState({
          updating: false,
        });
      });
  }

  render() {
    const { application } = this.props;
    const { enabled, updating } = this.state;

    return (
      <Section>
        <Box>
          <ContentHeader title={application.name} subtitle={application.id}>
            <Can action={PermissionEnum.UPDATE_APPLICATION}>
              <Button.Group size="small">
                {enabled ? (
                  <Button
                    onClick={this.revoke}
                    className="is-outlined is-danger"
                    disabled={updating}
                    loading={updating}
                  >
                    Revoke
                  </Button>
                ) : (
                  <Button
                    onClick={this.grant}
                    className="is-outlined is-primary"
                    disabled={updating}
                    loading={updating}
                  >
                    Grant
                  </Button>
                )}
              </Button.Group>
            </Can>
          </ContentHeader>
          <div>
            {application.description}
          </div>
        </Box>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.app.client,
});

export default connect(
  mapStateToProps,
)(Application);
