import { I18n } from 'aws-amplify';

const authScreenLabels = {
  en: {
    'Sign in to your account': 'Sign in to your Administrative account',
  },
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);
