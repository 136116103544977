import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clearUser } from 'redux/Wizard';
import {
  Button,
  Heading,
  Level,
  Section,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';

class StepReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectUser: null,
      submitting: false,
    };
  }

  submit = () => {
    const { user, enqueueSnackbar } = this.props;
    this.setState({
      submitting: true,
    });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user`;

    const adminCreateUserRequest = {
      Username: user.email,
      UserAttributes: [
        {
          Name: 'given_name',
          Value: user.first_name,
        },
        {
          Name: 'family_name',
          Value: user.last_name,
        },
        {
          Name: 'email',
          Value: user.email,
        },
      ],
      DesiredDeliveryMediums: [],
    };

    Request.post(url, {
      adminCreateUserRequest,
      GroupName: user.groupName,
      user,
    })
      .then((result) => {
        const { errorMessage, user } = result.data.adminCreationResponse;

        let username = null;
        clearUser();
        if (errorMessage !== '') {
          // errorMessage will exist in response only if user was created, but adding user to a group failed
          enqueueSnackbar(errorMessage, {
            variant: 'error',
            persist: true,
          });
        }
        username = user.User.Username;
        this.setState({ redirect: true, redirectUser: username });
      })
      .catch((error) => {
        const message = 'Unable to save user.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  onChange = () => {
    // Do nothing
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  render() {
    const { user } = this.props;
    const { redirect, redirectUser, submitting } = this.state;

    return (
      <div>
        { redirect
          && (
            <Redirect to={`/users/${redirectUser}`} />
          )}
        <Section>
          <Heading size={4}>Review User</Heading>
          <TextField
            label="Email"
            onChange={this.onChange}
            value={user.email || ''}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="First Name"
            onChange={this.onChange}
            value={user.first_name || ''}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="Last Name"
            onChange={this.onChange}
            value={user.last_name || ''}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="User Role"
            onChange={this.onChange}
            value={user.groupName || ''}
            margin="normal"
            fullWidth
            disabled
          />
        </Section>

        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" onClick={this.submit} loading={submitting}>Submit</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

StepReview = withSnackbar(StepReview);

const mapStateToProps = (state) => ({
  user: state.wizard.user,
  permissions: state.wizard.permissions,
});

export default connect(
  mapStateToProps,
)(StepReview);
