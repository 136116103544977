import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Heading,
  Level,
  Section,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { clearApplications, clearClient, clearUsers } from 'redux/Wizard';

class StepReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectClient: null,
      submitting: false,
    };
  }

  onChange = () => {
    /* Do nothing */
  }

  submit = () => {
    const {
      applications,
      client,
      enqueueSnackbar,
      users,
      clientType,
      clearUsers,
    } = this.props;

    this.setState({
      submitting: true,
    });

    const applicationIds = Object.keys(applications);

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client`;
    // If user exists, pass as an array
    const usersList = users ? [users] : [];

    client.type = clientType;
    client.name = client.name.trim();
    Request.post(url, {
      client,
      users: usersList,
      applications: applicationIds,
    })
      .then((result) => {
        // TODO: handle result.statusCode === 207 (user creation partially unsuccessful).
        if (result.data.id) {
          clearApplications();
          clearClient();
          this.setState({ redirect: true, redirectClient: result.data.id });
        } else {
          const message = 'Unable to onboard client (then): ';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to onboard client (catch): ';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        clearUsers();
        this.setState({
          submitting: false,
        });
      });
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  render() {
    const { applications, client, users } = this.props;
    const { redirect, redirectClient, submitting } = this.state;

    return (
      <div>
        { redirect
          && (
            <Redirect to={`/clients/subscriptions/${redirectClient}`} />
          )}
        <Section>
          <Heading size={4}>Client</Heading>
          <TextField
            label="Name"
            value={client.name || ''}
            onChange={this.onChange}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="Address"
            value={client.address || ''}
            onChange={this.onChange}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="Merchant Id"
            value={client.merchant_id || ''}
            onChange={this.onChange}
            margin="normal"
            fullWidth
            disabled
          />
        </Section>
        { users.email && (
          <Section>
            <Heading size={4}>Users</Heading>
            <TextField
              label="Email"
              value={users.email || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
            <TextField
              label="First Name"
              value={users.first_name || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
            <TextField
              label="Last Name"
              value={users.last_name || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
          </Section>
        )}
        {applications && Object.values(applications).length > 0 && (
          <Section>
            <Heading size={4}>Applications</Heading>
            {Object.entries(applications).map((application) => (
              <TextField
                key={`key-${application[1]}`}
                value={application[1] || ''}
                onChange={this.onChange}
                margin="normal"
                fullWidth
                disabled
              />
            ))}
          </Section>
        )}
        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" onClick={this.submit} loading={submitting}>Submit</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearUsers: () => dispatch(clearUsers()),
});

const mapStateToProps = (state) => ({
  applications: state.wizard.applications,
  client: state.wizard.client,
  users: state.wizard.users,
});

StepReview = withSnackbar(StepReview);

StepReview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepReview);

export default StepReview;
