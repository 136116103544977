/**
 * Sample Theme at https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme-Sample.tsx
 */

export const Container = {
  fontFamily: 'Lato',
  height: '100vh',

};

export const FormContainer = {
  background: '#eeeeec',
  boxSizing: 'border-box',
  height: '100%',
  margin: 'auto',
  paddingTop: '5%',
};

export const FormSection = {
  borderRadius: '1px',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
};

export const SectionHeader = {
  color: 'hsl(0, 0%, 29%)',
  fontWeight: '400',
  height: '0px',
  marginBottom: '60px',
  paddingTop: '100px',
  textAlign: 'center',
};

export const SectionFooterSecondaryContent = {
  display: 'none',
};

export const InputLabel = {
  color: 'hsl(0, 0%, 29%)',
};

export const Input = {
  border: '0',
  borderBottom: '1px solid hsl(195, 10%, 66%)',
  borderRadius: '1px',
  color: 'hsl(0, 0%, 29%)',
  fontFamily: 'Lato',
  outline: '0',
  padding: '6px 0 7px 0',
};

export const Button = {
  backgroundColor: 'hsl(215, 100%, 25%)',
  border: 'O',
  borderRadius: '1px',
  height: '2.5rem',
  letterSpacing: '1px',
  lineHeight: 'initial',
  minWidth: '6rem',
  padding: '0',
};

export const A = {
  color: 'hsl(215, 100%, 25%)',
  cursor: 'pointer',
};

const Bootstrap = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  sectionHeader: SectionHeader,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,
  inputLabel: InputLabel,
  input: Input,
  button: Button,
  a: A,
};

export default Bootstrap;
