import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import {
  ApplicationContent,
  Core,
  LinearProgress,
  NotFound,
  Page,
} from '@rlx-pros/react-foundation';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import {
  clearAuth, clearPermissionMap, clearPermissions, setAuth, setPermissionMap, setPermissions,
} from 'redux/App';
import Request from 'shared/requests/Request';
import Headerbar from './app/Headerbar';
import SignOut from './account/SignOut';
import Theme from './account/Theme';
import './account/SignIn';
import './account/Amplify';
import '@rlx-pros/react-foundation/dist/react-foundation.min.css';
import 'bulma-switch/dist/css/bulma-switch.min.css';
// Routes
import Applications from './Applications';
import Clients from './Clients';
import Client from './clients/Client';
import ClientCreate from './clients/Create';
import Dashboard from './Dashboard';
import Support from './Support';
import User from './users/User';
import UserCreate from './users/Create';
import Users from './Users';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissionsLoaded: false,
    };
  }

  componentDidMount() {
    this.loadAuth();
    this.loadPermissions();
  }

  componentWillUnmount() {
    const { clearAuth } = this.props;
    clearAuth();
    clearPermissionMap();
    clearPermissions();
  }

  loadAuth() {
    const { clearAuth, setAuth } = this.props;

    Auth.currentAuthenticatedUser({})
      .then((auth) => {
        setAuth(auth);
      })
      .catch((error) => {
        clearAuth();
        console.error('Current Authenticated User: ', error);
      });
  }

  loadPermissions() {
    const { setPermissionMap, setPermissions } = this.props;

    return Request.get(`${process.env.REACT_APP_ORCHESTRATOR}/permission`)
      .then((result) => {
        setPermissionMap(result.data.permissionMap);
        setPermissions(result.data.permissions);
        this.setState({ permissionsLoaded: true });
      })
      .catch((error) => {
        const message = 'Unable to retrieve application permissions.';
        console.error(message, error);
      });
  }

  render() {
    const { permissionsLoaded } = this.state;

    return (
      <BrowserRouter>
        { permissionsLoaded ? (
          <Page trackingCode={process.env.REACT_APP_GA_TRACKING_CODE}>
            <Core>
              <Headerbar />
              <ApplicationContent>
                <Switch>
                  {/* Default */}
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>

                  {/* Dashboard */}
                  <Route exact path="/dashboard" component={Dashboard} />

                  {/* Clients */}
                  <Route exact path="/clients/create" component={ClientCreate} />
                  <Route path="/clients/:type/:id" component={Client} />
                  <Route path="/clients" component={Clients} />

                  {/* Applications */}
                  <Route exact path="/applications" component={Applications} />

                  {/* Users */}
                  <Route exact path="/users" component={Users} />
                  <Route exact path="/users/create" component={UserCreate} />
                  <Route path="/users/:username" component={User} />

                  {/* Support */}
                  <Route path="/support" component={Support} />

                  {/* Other */}
                  <Route path="/sign-out" component={SignOut} />
                  <Route component={NotFound} />
                </Switch>
              </ApplicationContent>
            </Core>
          </Page>
        ) : (
          <Page trackingCode={process.env.REACT_APP_GA_TRACKING_CODE}>
            <LinearProgress style={{ width: '100%' }} />
          </Page>
        )}
      </BrowserRouter>
    );
  }
}

App = withAuthenticator(App, {
  theme: Theme,
});

const mapDispatchToProps = (dispatch) => ({
  setAuth: (auth) => dispatch(setAuth(auth)),
  clearAuth: () => dispatch(clearAuth()),
  setPermissionMap: (map) => dispatch(setPermissionMap(map)),
  clearPermissionMap: () => dispatch(clearPermissionMap()),
  setPermissions: (permissions) => dispatch(setPermissions(permissions)),
  clearPermissions: () => dispatch(clearPermissions()),
});

App = connect(null, mapDispatchToProps)(App);

export default App;
