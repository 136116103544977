import React, { Component, createRef } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Table } from '@rlx-pros/react-foundation';

class PartnerSkusTable extends Component {
  constructor(props) {
    super(props);

    this.tableRef = createRef();

    const filters = queryString.parse(props.location.search);

    let columns = [
      {
        title: 'SKU',
        field: 'sku',
        editable: 'never',
      },
      {
        title: 'ASIN',
        field: 'asin',
        editable: 'never',
      },
      {
        title: 'Title',
        field: 'productName',
        editable: 'never',
      },
    ];

    columns = columns.map((column) => {
      if (filters[column.field] && !column.lookup) {
        // eslint-disable-next-line no-param-reassign
        column.defaultFilter = filters[column.field];
      }

      return column;
    });

    this.state = {
      columns,
      selectedRows: [],
    };
  }

  render() {
    const { columns } = this.state;
    const { loading, data, updateSelectedRows } = this.props;
    return (
      <Table
        tableRef={this.tableRef}
        borderless
        loading={loading}
        columns={columns}
        data={data}
        onSelectionChange={(selectedRows) => {
          this.setState({ selectedRows });
          updateSelectedRows(selectedRows);
        }}
        onFilterChange={() => {
          const { selectedRows } = this.state;

          // deselect all
          for (let i = 0; i < selectedRows.length; i += 1) {
            const { tableData } = selectedRows[i];
            if (tableData.checked) {
              tableData.checked = false;
            }
          }

          this.tableRef.current.dataManager.selectedCount = 0;
          this.tableRef.current.setState({ selectedCount: 0 });
          this.setState({ selectedRows: [] });
          updateSelectedRows(selectedRows);
        }}
        options={{
          filtering: true,
          grouping: false,
          search: false,
          draggable: false,
          selection: true,
          sorting: true,
          toolbar: true,
          showTitle: false,
        }}
      />
    );
  }
}

PartnerSkusTable = withRouter(PartnerSkusTable);

export default PartnerSkusTable;
