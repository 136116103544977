import React, { Component } from 'react';
import { clearUsers } from 'redux/Wizard';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ContentHeader,
  Section,
  StepWizard,
} from '@rlx-pros/react-foundation';
import Can from 'shared/components/Can';
import ClientTypeEnum from 'shared/enum/ClientTypeEnum';
import PermissionEnum from 'shared/enum/PermissionEnum';
import StepApplications from './create/StepApplications';
import StepClient from './create/StepClient';
import StepNavigation from './create/StepNavigation';
import StepPartnerCampaigns from './create/StepPartnerCampaigns';
import StepPartnerDetails from './create/StepPartnerDetails';
import StepPartnerSkus from './create/StepPartnerSkus';
import StepPartnerType from './create/StepPartnerType';
import StepRetailReview from './create/StepRetailReview';
import StepReview from './create/StepReview';
import StepUsers from './create/StepUsers';

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientType: '',
      partnerName: '',
      partnerCampaigns: [],
      partnerSkus: [],
      prefixes: [],
    };
  }

  updateClientType = (clientType) => {
    this.setState({ clientType });
  }

  updatePartnerName = (partnerName) => {
    this.setState({ partnerName });
  }

  updatePartnerCampaigns = (partnerCampaigns) => {
    this.setState({ partnerCampaigns });
  }

  updatePartnerSkus = (partnerSkus) => {
    this.setState({ partnerSkus });
  }

  updatePrefixes = (prefixes) => {
    this.setState({ prefixes });
  }

  cancel = () => {
    const { clearUsers } = this.props;
    clearUsers();
  }

  render() {
    const {
      clientType, partnerName, partnerCampaigns, partnerSkus, prefixes,
    } = this.state;
    return (
      <Can action={PermissionEnum.CREATE_CLIENTS}>
        <div>
          <ContentHeader title={`${partnerName} Platform Setup`} size="medium" color="white">
            <Button.Group size="small" onClick={this.cancel}>
              <Link to="/clients" className="button is-outlined is-rounded is-danger">Cancel</Link>
            </Button.Group>
          </ContentHeader>

          <Section>
            <Box>
              {clientType === '' && <StepPartnerType updateClientType={this.updateClientType} />}
              {clientType === ClientTypeEnum.RETAIL.toLowerCase()
                  && (
                  <StepWizard nav={<StepNavigation clientType={clientType.toLocaleLowerCase()} />} initialStep="1">
                    <StepPartnerDetails updatePartnerName={this.updatePartnerName} updatePrefixes={this.updatePrefixes} />
                    <StepUsers />
                    <StepPartnerSkus prefixes={prefixes} updatePartnerSkus={this.updatePartnerSkus} />
                    <StepPartnerCampaigns prefixes={prefixes} updatePartnerCampaigns={this.updatePartnerCampaigns} />
                    <StepRetailReview partnerName={partnerName} prefixes={prefixes} partnerCampaigns={partnerCampaigns} partnerSkus={partnerSkus} />
                  </StepWizard>
                  )}
              {clientType === ClientTypeEnum.AGENCY.toLowerCase()
                  && (
                  <StepWizard nav={<StepNavigation clientType={clientType.toLocaleLowerCase()} />} initialStep="1">
                    <StepClient />
                    <StepUsers />
                    <StepApplications />
                    <StepReview clientType={clientType} />
                  </StepWizard>
                  )}
              {clientType === ClientTypeEnum.MANAGED_SERVICE.toLowerCase()
                  && (
                  <StepWizard nav={<StepNavigation clientType={clientType.toLocaleLowerCase()} />} initialStep="1">
                    <StepClient />
                    <StepUsers />
                    <StepApplications />
                    <StepReview clientType={clientType} />
                  </StepWizard>
                  )}
              {clientType === ClientTypeEnum.SAAS
                  && (
                  <StepWizard nav={<StepNavigation clientType={clientType.toLocaleLowerCase()} />} initialStep="1">
                    <StepClient />
                    <StepUsers />
                    <StepApplications />
                    <StepReview />
                  </StepWizard>
                  )}
            </Box>
          </Section>
        </div>
      </Can>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearUsers: () => dispatch(clearUsers()),
});

export default connect(
  null,
  mapDispatchToProps,
)(Create);
