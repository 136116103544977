import React, { Component } from 'react';
import {
  Button,
  Modal,
  ReduxTextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { email, required } from 'shared/fieldValidation';
import { Field as ReduxField, reduxForm } from 'redux-form';

class CreateClientUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      submitting: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.isOpen,
      submitting: nextProps.submitting,
    };
  }

  close = () => {
    const { closeCallback, reset } = this.props;
    reset();
    closeCallback();
  };

  createUser = async (values) => {
    const { createClientUserCallback, reset } = this.props;
    this.setState({
      submitting: true,
    });
    const resetForm = await createClientUserCallback(values);
    if (resetForm) {
      reset();
    }
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isOpen, submitting } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        contentLabel="Create New Client User"
      >
        <form id="form-create-client-user" onSubmit={handleSubmit(this.createUser)}>
          <div>
            <ReduxField
              name="first_name"
              label="First Name"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
          </div>
          <div>
            <ReduxField
              name="last_name"
              label="Last Name"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
          </div>
          <div>
            <ReduxField
              name="email"
              label="Email Address"
              component={ReduxTextField}
              validate={[required, email]}
              margin="normal"
              fullWidth
            />
          </div>
        </form>
        <br />
        <Button.Group position="right">
          <Button disabled={submitting} onClick={this.close}>Cancel</Button>
          <Button
            color="info"
            form="form-create-client-user"
            loading={submitting}
            disabled={pristine}
          >
            Create
          </Button>
        </Button.Group>
      </Modal>
    );
  }
}

CreateClientUserModal = reduxForm({
  form: 'form-create-client-user',
  enableReinitialize: true,
})(CreateClientUserModal);

CreateClientUserModal = withSnackbar(CreateClientUserModal);

export default CreateClientUserModal;
