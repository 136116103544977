import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { OK } from 'http-status-codes';
import {
  Section,
  Table,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Can from 'shared/components/Can';
import { userCanAccess } from 'shared/permission';
import PermissionEnum from 'shared/enum/PermissionEnum';
import PasswordResetModal from './users/PasswordResetModal';
import CreateClientUserModal from './users/CreateClientUserModal';
import DeleteUserModal from './users/DeleteUserModal';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateClientUserModalOpen: false,
      isCreateClientUserModalProcessing: false,
      isDeleteClientUserModalOpen: false,
      isDeleteClientUserModalProcessing: false,
      isPasswordResetModalOpen: false,
      isPasswordResetModalProcessing: false,
      loading: true,
      user: null,
      users: [],
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = () => {
    const { enqueueSnackbar, match } = this.props;
    this.setState({
      loading: true,
    });

    const clientType = match.params.type;
    const clientId = match.params.id;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/users`;

    Request.get(url, {
      params: {
        filterKey: clientType === 'retail' ? 'custom:retail_client_id' : 'custom:client_id',
        filterValue: clientId,
      },
    })
      .then((result) => {
        this.setState({
          users: result.data,
        });
      })
      .catch((error) => {
        const message = 'Unable to get users.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  closeCreateClientUserModal = () => {
    this.setState({
      isCreateClientUserModalOpen: false,
    });
  };

  createClientUser = async (values) => {
    this.setState({
      isCreateClientUserModalProcessing: true,
    });

    const { enqueueSnackbar, match } = this.props;
    const clientId = match.params.id;
    const clientType = match.params.type;

    let resetForm = true;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/user`;

    await Request.post(url, {
      clientId,
      clientType,
      user: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data.statusCode === OK) {
          enqueueSnackbar('User was successfully created for the client.', {
            variant: 'success',
            persist: false,
          });
          this.setState({
            isCreateClientUserModalProcessing: false,
            isCreateClientUserModalOpen: false,
          });
          this.requestData();
        } else {
          const errorMessage = data.data.message ? data.data.message : 'Request failed to create client user.';
          throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        const message = error ? error.message : 'An error occurred during the client user creation.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);

        this.setState({
          isCreateClientUserModalProcessing: false,
        });

        resetForm = false;
      });

    return resetForm;
  };

  closeModal = () => {
    this.setState({
      isPasswordResetModalOpen: false,
    });
  };

  resetPassword = async () => {
    this.setState({
      isPasswordResetModalProcessing: true,
    });
    const { enqueueSnackbar } = this.props;
    const { user } = this.state;
    const { Username, UserStatus } = user;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/password-reset`;

    await Request.put(url, {
      params: {
        Username,
        UserStatus,
      },
    })
      .then((result) => {
        const { status } = result;
        if (status === OK) {
          enqueueSnackbar('Password was successfully reset for the user', {
            variant: 'success',
            persist: false,
          });
        } else {
          throw new Error('Returned status code indicates failure to reset password');
        }
      })
      .catch((error) => {
        const message = 'An error occurred during forcing a user password reset.';
        enqueueSnackbar(message, { variant: 'error ', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isPasswordResetModalProcessing: false,
          isPasswordResetModalOpen: false,
          user: null,
        });
      });
  }

  closeDeleteClientUserModal = () => {
    this.setState({
      isDeleteClientUserModalOpen: false,
    });
  };

  deleteClientUser = async () => {
    this.setState({
      isDeleteClientUserModalProcessing: true,
    });
    const { enqueueSnackbar } = this.props;
    const { user } = this.state;
    const { Username } = user;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/user`;

    await Request.delete(url, {
      data: {
        Username,
      },
      validateStatus: () => true,
    })
      .then((result) => {
        const { status, data } = result;
        if (status === OK && data.statusCode === OK) {
          enqueueSnackbar('User was successfully deleted for the client.', {
            variant: 'success',
            persist: false,
          });
          this.requestData();
        } else {
          const errorMessage = data.message ? data.message : 'Request failed to delete client user.';
          throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        const message = error ? error.message : 'An error occurred during the client user deletion.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isDeleteClientUserModalProcessing: false,
          isDeleteClientUserModalOpen: false,
          user: null,
        });
      });
  }

  handleUpdate = (newData, oldData, resolve, reject) => {
    const { enqueueSnackbar } = this.props;
    const { Username, Enabled } = newData;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/user/status`;

    Request.put(url, {
      Username,
      Enabled: Enabled === 'true',
    })
      .then((result) => {
        if (result.data.data) {
          const { data } = result.data;
          const { body, statusCode } = data;

          if (statusCode !== OK) {
            const parseBody = JSON.parse(body);
            const errorMessage = `Client User failed to update. ${parseBody.message}`;
            enqueueSnackbar(errorMessage, { variant: 'error', persist: false });
            console.error(errorMessage, data);
            reject();
          } else {
            const { users } = this.state;
            const index = users.indexOf(oldData);
            users[index].Enabled = newData.Enabled === 'true';
            this.setState({ users });
            resolve(newData);
          }
        } else {
          throw new Error('Request failed to enable/disable client user.');
        }
      })
      .catch((error) => {
        const message = 'An error occurred when enabling/disabling client user.';
        enqueueSnackbar(message, { variant: 'error ', persist: true });
        console.error(message, error);
        reject();
      });
  }

  renderPasswordResetAsAction = () => {
    let columnAction = null;

    if (userCanAccess(PermissionEnum.RESET_CLIENT_USER_PASSWORD)) {
      columnAction = {
        icon: 'vpn_key',
        tooltip: 'Force User Password Reset',
        position: 'row',
        onClick: (event, user) => {
          event.preventDefault();
          this.setState({
            user,
            isPasswordResetModalOpen: true,
          });
        },
      };
    }

    return columnAction;
  }

  render() {
    const {
      isCreateClientUserModalOpen,
      isCreateClientUserModalProcessing,
      isDeleteClientUserModalOpen,
      isDeleteClientUserModalProcessing,
      isPasswordResetModalOpen,
      isPasswordResetModalProcessing,
      users,
      loading,
    } = this.state;

    const columns = [
      {
        title: 'Username',
        field: 'Username',
        editable: 'never',
        customSort: (a, b) => Table.sort.insensitive('Username', a, b),
        defaultSort: 'asc',
      },
      {
        title: 'Email',
        field: 'UserMap.email',
        editable: 'never',
        customSort: (a, b) => Table.sort.insensitive('UserMap.email', a, b),
      },
      {
        title: 'Status',
        field: 'UserStatus',
        editable: 'never',
      },
      {
        title: 'Enabled',
        field: 'Enabled',
        lookup: { true: 'Yes', false: 'No' },
        editable: userCanAccess(PermissionEnum.UPDATE_CLIENT_USER_STATUS) ? 'onUpdate' : false,
        render: (cellValue) => {
          if (typeof cellValue === 'object') {
            return (cellValue.Enabled ? 'Yes' : 'No');
          }

          return cellValue;
        },
      },
    ];

    const actions = [
      {
        icon: 'refresh',
        tooltip: 'Refresh',
        isFreeAction: true,
        onClick: this.requestData,
      },
      () => this.renderPasswordResetAsAction(),
    ];

    // if (userCanAccess(PermissionEnum.CREATE_CLIENT_USER)) {
    actions.push({
      icon: 'person_add',
      tooltip: 'Add User',
      isFreeAction: true,
      onClick: (event) => {
        event.preventDefault();
        this.setState({
          isCreateClientUserModalOpen: true,
        });
      },
    });
    // }

    // if (userCanAccess(PermissionEnum.DELETE_CLIENT_USER)) {
    actions.push({
      icon: 'person_remove',
      tooltip: 'Delete User',
      onClick: (event, user) => {
        event.preventDefault();
        this.setState({
          user,
          isDeleteClientUserModalOpen: true,
        });
      },
    });
    // }

    let userData = [];
    if (users && users.length > 0) {
      userData = users.sort((a, b) => (a.Username.toLowerCase() > b.Username.toLowerCase() ? 1 : -1));
    }

    return (
      <Section>
        <Table
          actions={actions}
          title="Users"
          columns={columns}
          data={userData}
          loading={loading}
          editable={userCanAccess(PermissionEnum.UPDATE_CLIENT_USER_STATUS) ? {
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              this.handleUpdate(newData, oldData, resolve, reject);
            }),
          } : {}}
          options={{
            filtering: true,
            grouping: true,
            sorting: true,
          }}
        />
        <Can action={PermissionEnum.CREATE_CLIENT_USER}>
          <CreateClientUserModal
            isOpen={isCreateClientUserModalOpen}
            isProcessing={isCreateClientUserModalProcessing}
            closeCallback={this.closeCreateClientUserModal}
            createClientUserCallback={this.createClientUser}
          />
        </Can>
        <Can action={PermissionEnum.DELETE_CLIENT_USER}>
          <DeleteUserModal
            isOpen={isDeleteClientUserModalOpen}
            isProcessing={isDeleteClientUserModalProcessing}
            closeCallback={this.closeDeleteClientUserModal}
            deleteUserCallback={this.deleteClientUser}
          />
        </Can>
        <Can action={PermissionEnum.RESET_CLIENT_USER_PASSWORD}>
          <PasswordResetModal
            isOpen={isPasswordResetModalOpen}
            isProcessing={isPasswordResetModalProcessing}
            closeCallback={this.closeModal}
            resetPasswordCallback={this.resetPassword}
          />
        </Can>
      </Section>
    );
  }
}

Users = withSnackbar(Users);

export default Users;
