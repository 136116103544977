import MarketplaceEnum from 'shared/enum/MarketplaceEnum';
import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Heading,
  Level,
  Section,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { clearUsers } from 'redux/Wizard';

class StepRetailReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectClient: null,
      submitting: false,
    };
  }

  onChange = () => {
    /* Do nothing */
  }

  submit = () => {
    const {
      enqueueSnackbar,
      users,
      partnerName,
      partnerCampaigns,
      partnerSkus,
      prefixes,
      clearUsers,
    } = this.props;

    this.setState({
      submitting: true,
    });

    const partnerPrefixes = prefixes.map((eachPrefix) => eachPrefix.prefix);

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/onboarding-retail`;
    // If user exists, pass as an array
    const usersList = users ? [users] : [];

    Request.post(url, {
      partnerName: partnerName.trim(),
      partnerPrefixes,
      partnerCampaigns,
      partnerSkus,
      users: usersList,
      marketplaceId: MarketplaceEnum.US,
    })
      .then((result) => {
        if (result.data) {
          this.setState({ redirect: true, redirectClient: `retail/${result.data}` });
        } else {
          const message = 'Unable to onboard client (then): ';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to onboard client (catch): ';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        clearUsers();
        this.setState({
          submitting: false,
        });
      });
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  render() {
    const { partnerName, prefixes, users } = this.props;
    const displayPrefixes = prefixes.map(((eachPrefix) => eachPrefix.prefix));
    const { redirect, redirectClient, submitting } = this.state;

    return (
      <div>
        { redirect
          && (
            <Redirect to={`/clients/${redirectClient}`} />
          )}
        <Section>
          <Heading size={4}>Review Client</Heading>
          <TextField
            label="Partner Name"
            value={partnerName}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label="Partner Prefixes"
            value={displayPrefixes.join(',')}
            margin="normal"
            fullWidth
            disabled
          />
        </Section>
        { users.email && (
          <Section>
            <Heading size={4}>Review Users</Heading>
            <TextField
              label="Email"
              value={users.email || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
            <TextField
              label="First Name"
              value={users.first_name || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
            <TextField
              label="Last Name"
              value={users.last_name || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
              disabled
            />
          </Section>
        )}
        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" onClick={this.submit} loading={submitting}>Submit</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearUsers: () => dispatch(clearUsers()),
});

const mapStateToProps = (state) => ({
  applications: state.wizard.applications,
  client: state.wizard.client,
  users: state.wizard.users,
});

StepRetailReview = withSnackbar(StepRetailReview);

StepRetailReview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepRetailReview);

export default StepRetailReview;
