// -----------------------------------------------------------------------------
// Reducer
// -----------------------------------------------------------------------------
const actions = {};
const initialState = {
  auth: {},
  client: {},
  permissionMap: {},
  permissions: {},
  user: {},
};

const App = (state = initialState, action) => {
  const handler = actions[action.type];
  return handler ? handler(state, action) : state;
};

export default App;

// -----------------------------------------------------------------------------
// Actions
// -----------------------------------------------------------------------------

/*
 * Auth
 */
export const SET_AUTH = 'APP_SET_AUTH';
export const CLEAR_AUTH = 'APP_CLEAR_AUTH';

/*
 * Client
 */
export const SET_CLIENT = 'APP_SET_CLIENT';
export const CLEAR_CLIENT = 'APP_CLEAR_CLIENT';

/*
 * PermissionMap
 */
export const SET_PERMISSION_MAP = 'APP_SET_PERMISSION_MAP';
export const CLEAR_PERMISSION_MAP = 'APP_CLEAR_PERMISSION_MAP';

/*
 * Permissions
 */
export const SET_PERMISSIONS = 'APP_SET_PERMISSIONS';
export const CLEAR_PERMISSIONS = 'APP_CLEAR_PERMISSIONS';

/*
 * User
 */
export const SET_USER = 'APP_SET_USER';
export const CLEAR_USER = 'APP_CLEAR_USER';

// -----------------------------------------------------------------------------
// Action Creators
// -----------------------------------------------------------------------------

/*
 * Auth
 */
export const setAuth = (auth) => ({
  type: SET_AUTH,
  payload: auth,
});

export const clearAuth = () => ({
  type: CLEAR_AUTH,
});

/*
 * Client
 */
export const setClient = (client) => ({
  type: SET_CLIENT,
  payload: client,
});

export const clearClient = () => ({
  type: CLEAR_CLIENT,
});

/*
 * PermissionMap
 */
export const setPermissionMap = (map) => ({
  type: SET_PERMISSION_MAP,
  payload: map,
});

export const clearPermissionMap = () => ({
  type: CLEAR_PERMISSION_MAP,
});

/*
 * Permissions
 */
export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const clearPermissions = () => ({
  type: CLEAR_PERMISSIONS,
});

/*
 * User
 */
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

// -----------------------------------------------------------------------------
// Actions Handlers
// -----------------------------------------------------------------------------

/*
 * Auth
 */
actions[SET_AUTH] = function (state, action) {
  return { ...state, auth: action.payload };
};

actions[CLEAR_AUTH] = function (state) {
  return { ...state, auth: {} };
};

/*
 * Client
 */
actions[SET_CLIENT] = function (state, action) {
  return { ...state, client: action.payload };
};

actions[CLEAR_CLIENT] = function (state) {
  return { ...state, client: {} };
};

/*
 * Permission Map
 */
actions[SET_PERMISSION_MAP] = function (state, action) {
  return { ...state, permissionMap: action.payload };
};

actions[CLEAR_PERMISSION_MAP] = function (state) {
  return { ...state, permissionMap: {} };
};

/*
 * Permissions
 */
actions[SET_PERMISSIONS] = function (state, action) {
  return { ...state, permissions: action.payload };
};

actions[CLEAR_PERMISSIONS] = function (state) {
  return { ...state, permissions: {} };
};

/*
 * User
 */
actions[SET_USER] = function (state, action) {
  return { ...state, user: action.payload };
};

actions[CLEAR_USER] = function (state) {
  return { ...state, user: {} };
};
