import React from 'react';
import ClientContext from './ClientContext';

const withClient = (Component) => {
  const WrappedComponent = React.forwardRef((props, ref) => (
    <ClientContext.Consumer>
      {(context) => {
        if (context) {
          return (
            <Component
              {...props}
              ref={ref}
              client={context.client}
              isClientLoading={context.loading}
              reloadClient={context.requestData}
            />
          );
        }

        return (<Component {...props} ref={ref} />);
      }}
    </ClientContext.Consumer>
  ));

  return WrappedComponent;
};

export default withClient;
