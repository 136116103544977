import Request from 'shared/requests/Request';
import { Auth } from 'aws-amplify';
import { OK } from 'http-status-codes';

export const updateAuthorizedClient = async (username, clientId) => {
  const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/update-authorized-client`;
  const body = {
    Username: username,
    AuthorizedClient: clientId,
  };

  return Request.post(url, body);
};

export const updateAuthorizedRetailClient = async (username, clientId) => {
  const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/update-authorized-retail-client`;
  const body = {
    Username: username,
    AuthorizedRetailClient: clientId,
  };

  return Request.post(url, body);
};

export const getAuthorizedClientIds = async () => Auth.currentAuthenticatedUser()
  .then((currentUser) => {
    if (currentUser) {
      return Request.get(
        `${process.env.REACT_APP_ORCHESTRATOR}/client/get-user-attributes`,
        {
          params: {
            Username: currentUser.attributes.email,
          },
        },
      )
        .then((clientUser) => {
          // Default for the case that this user was not resolved (possibly does not exist in the client pool yet)
          let authorizedAs = null;
          let retailAuthorizedAs = null;

          if (
            clientUser.status === OK && clientUser.data && clientUser.data.UserMap
            && clientUser.data.UserMap['custom:authorized_as']
          ) {
            authorizedAs = clientUser.data.UserMap['custom:authorized_as'];
          }

          if (
            clientUser.status === OK && clientUser.data && clientUser.data.UserMap
            && clientUser.data.UserMap['custom:retail_authorized_as']
          ) {
            retailAuthorizedAs = clientUser.data.UserMap['custom:retail_authorized_as'];
          }

          return { authorizedAs, retailAuthorizedAs };
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    }

    return null;
  });
