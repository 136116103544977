import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const required = (value) => (value ? undefined : 'Required');

/* eslint-disable no-mixed-operators */
export const nonAdminEmail = (value) => (value && !(value.toLowerCase().endsWith('@kaspien.com') || value.toLowerCase().endsWith('@etailz.com')) || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid domain - please use a Kaspien.com or etailz.com email address.'
  : undefined);

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Example: user@example.com'
  : undefined);

export const tel = (value) => (value && parsePhoneNumberFromString(value, 'US')
  ? undefined
  : 'Invalid');

export const notAmazonMarketplaceId = (value) => {
  /* eslint-disable no-multi-spaces */
  const ids = [
    'A2Q3Y263D00KWC',   // Brazil
    'A2EUQ1WTGCTBG2',   // Canada
    'A1AM78C64UM0Y8',   // Mexico
    'ATVPDKIKX0DER',    // US
    'A2VIGQ35RCS4UG',   // United Arab Emirates (U.A.E.)
    'A1PA6795UKMFR9',   // Germany
    'ARBP9OOSHTCHU',    // Egypt
    'A1RKKUPIHCS9HS',   // Spain
    'A13V1IB3VIYZZH',   // France
    'A1F83G8C2ARO7P',   // UK
    'A21TJRUUN4KGV',    // India
    'APJ6JRA9NG5V4',    // Italy
    'A17E79C6D8DWNP',   // Saudi Arabia
    'A33AVAJ2PDY3EV',   // Turkey
    'A19VAU5U5O7RUS',   // Singapore
    'A39IBJ37TRP1C6',   // Australia
    'A1VC38T7YXB528',   // Japan
  ];
  /* eslint-enable no-multi-spaces */

  const message = 'The value entered is an Amazon Marketplace ID. Please enter the client\'s Merchant ID.';

  return (!ids.includes(value) ? undefined : message);
};
