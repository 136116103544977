import React from 'react';
import { Heading } from '@rlx-pros/react-foundation';

const StepNavigation = (props) => {
  const { currentStep } = props;

  const steps = [
    {
      number: 1,
      title: 'User',
      state: (currentStep === 1 ? 'is-active' : (currentStep > 1 ? 'is-completed' : '')),
    },
    {
      number: 2,
      title: 'Review',
      state: (currentStep === 2 ? 'is-active' : (currentStep > 2 ? 'is-completed' : '')),
    },
  ];

  return (
    <div className="steps">
      {steps.map((step, index) => {
        const key = `key-${index}`;

        return (
          <div key={key} className={`step-item is-info ${step.state}`}>
            <div className="step-marker">
              { step.number }
            </div>
            <div className="step-details">
              <Heading size={6}>
                { step.title }
              </Heading>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepNavigation;
