/* eslint-disable no-undef */
import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
  Section,
  Table,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import ClientTypeEnum from 'shared/enum/ClientTypeEnum';
import PermissionEnum from 'shared/enum/PermissionEnum';
import dateFilterEpoch from 'shared/table';
import { updateAuthorizedClient } from 'shared/requests/authorizedClient';
import { userCanAccess } from 'shared/permission';
import withClients from './withClients';

class Subscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
    };
  }

  invokeUpdateAuthorizedClient = async (event, rowData) => {
    const { auth, enqueueSnackbar, requestData } = this.props;
    this.setState({
      showLoading: true,
    });

    updateAuthorizedClient(auth.username, rowData.id)
      .then(async (result) => {
        if (result.data === 'Success') {
          // Automatically login this user in the platform client portal.
          window.open(
            `${process.env.REACT_APP_CLIENT_AUTHENTICATION_DOMAIN}/refresh?redirectUrl=${process.env.REACT_APP_CLIENT_PORTAL_DOMAIN}`,
            '_blank',
          );
          await requestData();
        } else {
          const message = 'Unable to authorize as client.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to authorize as client.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          showLoading: false,
        });
      });
  }

  openPlatformForAuthorizedClient = () => {
    window.open(
      `${process.env.REACT_APP_CLIENT_PORTAL_DOMAIN}`,
      '_blank',
    );
  }

  openSellerCentralForManagedClient = (url) => {
    window.open(
      url,
      '_blank',
    );
  }

  renderLoginAsAction = (rowData) => {
    let columnAction = null;

    if (userCanAccess(PermissionEnum.AUTHORIZE_CLIENT_ACCESS)) {
      if (rowData.isAuthorizedClient) {
        columnAction = {
          icon: 'supervisor_account',
          tooltip: 'Open Platform',
          onClick: this.openPlatformForAuthorizedClient,
        };
      } else {
        columnAction = {
          icon: 'insert_link',
          tooltip: 'Login as this client',
          onClick: this.invokeUpdateAuthorizedClient,
        };
      }
    }

    return columnAction;
  }

  renderManagedClientAsAction = (rowData) => ({
    icon: 'open_in_browser',
    tooltip: 'Open Managed Client in Seller Central',
    position: 'row',
    disabled: !rowData.managedClientUrl,
    onClick: (event, rowData) => {
      this.openSellerCentralForManagedClient(rowData.managedClientUrl);
    },
  });

  render() {
    const {
      clients,
      history,
      isLoading,
      requestData,
    } = this.props;

    let { showLoading } = this.state;
    showLoading = showLoading || isLoading;

    let data = [];
    if (clients) {
      data = clients.filter((client) => client.type !== 'retail').sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    }

    const columns = [
      {
        title: 'Name',
        field: 'name',
        customSort: (a, b) => Table.sort.insensitive('name', a, b),
        defaultSort: 'asc',
      },
      {
        title: 'Status',
        field: 'status',
        customSort: (a, b) => Table.sort.insensitive('status', a, b),
      },
      {
        title: 'Type',
        field: 'type',
        customSort: (a, b) => Table.sort.insensitive('type', a, b),
        lookup: {
          agency: ClientTypeEnum.AGENCY,
          'managed service': ClientTypeEnum.MANAGED_SERVICE,
          SaaS: ClientTypeEnum.SAAS,
        },
      },
      // {
      //   title: 'Ad Manager Schedules',
      //   field: 'adManagerSchedule',
      //   render: (rowData) => (rowData.adManagerSchedule === '' ? ''
      //     : (rowData.adManagerSchedule ? 'Enabled' : 'Disabled')),
      //   lookup: {
      //     true: 'Enabled',
      //     false: 'Disabled',
      //   },
      // },
      // {
      //   title: 'Channel Auditor Schedules',
      //   field: 'channelAuditorSchedule',
      //   render: (rowData) => (rowData.channelAuditorSchedule === '' ? ''
      //     : (rowData.channelAuditorSchedule ? 'Enabled' : 'Disabled')),
      //   lookup: {
      //     true: 'Enabled',
      //     false: 'Disabled',
      //   },
      // },
      // {
      //   title: 'Agreements Accepted',
      //   field: 'hasAcceptedContract',
      //   render: (rowData) => (rowData.hasAcceptedContract ? 'Accepted' : 'Not Accepted'),
      //   lookup: {
      //     true: 'Accepted',
      //     false: 'Not Accepted',
      //   },
      // },
      // {
      //   title: 'Agreements Accepted On',
      //   field: 'contract.createdAt',
      //   render: (rowData) => (rowData.hasAcceptedContract
      //     ? <Moment unix format="MM.DD.YYYY">{rowData.contract.createdAt / 1000}</Moment>
      //     : ''),
      // },
      // {
      //   title: 'AAA Authorization',
      //   field: 'aaaAuthorized',
      //   render: (rowData) => (rowData.aaaAuthorized ? 'Complete' : 'Not Complete'),
      //   lookup: {
      //     true: 'Complete',
      //     false: 'Not Complete',
      //   },
      // },
      // {
      //   title: 'SP Authorization',
      //   field: 'spAuthorized',
      //   render: (rowData) => (rowData.spAuthorized ? 'Complete' : 'Not Complete'),
      //   lookup: {
      //     true: 'Complete',
      //     false: 'Not Complete',
      //   },
      // },
      // {
      //   title: 'MWS Authorization',
      //   field: 'mwsAuthorized',
      //   render: (rowData) => (rowData.mwsAuthorized ? 'Complete' : 'Not Complete'),
      //   lookup: {
      //     true: 'Complete',
      //     false: 'Not Complete',
      //   },
      // },
      {
        title: 'Created',
        field: 'createdAt',
        customFilterAndSearch: (search, data) => dateFilterEpoch('createdAt', search, data),
        render: (rowData) => <Moment unix format="MM.DD.YYYY">{rowData.createdAt / 1000}</Moment>,
      },
      {
        title: 'Id',
        field: 'id',
        customSort: (a, b) => Table.sort.insensitive('id', a, b),
      },
    ];

    const actions = [
      {
        icon: 'refresh',
        tooltip: 'Refresh',
        isFreeAction: true,
        onClick: requestData,
      },
      {
        icon: 'launch',
        tooltip: 'View Client',
        position: 'row',
        onClick: (event, rowData) => {
          history.push(`/clients/subscriptions/${rowData.id}`);
        },
      },
      (rowData) => this.renderLoginAsAction(rowData),
      (rowData) => this.renderManagedClientAsAction(rowData),
    ];

    return (
      <Section>
        <Table
          actions={actions}
          loading={showLoading}
          options={{
            filtering: true,
            grouping: true,
            sorting: true,
          }}
          columns={columns}
          data={data}
          title="Clients"
        />
      </Section>
    );
  }
}

Subscriptions = withClients(Subscriptions);
Subscriptions = withSnackbar(Subscriptions);

const mapStateToProps = (state) => ({
  auth: state.app.auth,
});

export default connect(
  mapStateToProps,
)(Subscriptions);
