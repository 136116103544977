import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import ToggleSwitch from 'shared/components/ToggleSwitch';
import { setApplications } from 'redux/Wizard';
import {
  Button,
  ContentHeader,
  Level,
  Section,
} from '@rlx-pros/react-foundation';

class StepApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
    };
  }

  componentDidMount() {
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/application`;

    Request.get(url)
      .then((result) => {
        const data = result.data.applications.sort((x, y) => {
          const a = x.name.toUpperCase();
          const b = y.name.toUpperCase();

          return (a < b ? -1 : (a > b ? 1 : 0));
        });
        this.setState({ applications: data });
      })
      .catch((error) => {
        console.error('Onboarding Applications: ', error);
      });
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  next = (data) => {
    const { nextStep, setApplications } = this.props;
    const { applications } = this.state;

    const details = {};
    Object.entries(data).forEach((eachApplication) => {
      const application = applications.filter((application) => application.id === eachApplication[0]);
      if (eachApplication[1]) {
        details[eachApplication[0]] = application[0].name;
      }
    });

    setApplications(details);
    nextStep();
  }

  render() {
    const { handleSubmit } = this.props;
    const { applications } = this.state;
    return (
      <div>
        <form id="onboarding-applications" onSubmit={handleSubmit(this.next)}>
          <div>
            {applications.map((application) => {
              if (application.status !== 'disabled') {
                return (
                  <div key={application.id} style={{ background: 'rgba(0,0,0,0.04)', marginBottom: '16px' }}>
                    <ContentHeader title={application.name} subtitle={application.id}>
                      <Button.Group>
                        <ReduxField component={ToggleSwitch} name={application.id} label="" />
                      </Button.Group>
                    </ContentHeader>
                    <div style={{ padding: '0 24px  24px' }}>
                      {application.description}
                    </div>
                  </div>
                );
              }

              return (<div key={application.id} />);
            })}
          </div>
        </form>

        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-applications">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setApplications: (client) => dispatch(setApplications(client)),
});

export default connect(
  null,
  mapDispatchToProps,
)(reduxForm({
  form: 'onboarding-applications',
})(StepApplications));
