import React from 'react';
import PartnerCampaignContext from './PartnerCampaignContext';

const withConsumer = (Component) => {
  const WrappedComponent = React.forwardRef((props, ref) => (
    <PartnerCampaignContext.Consumer>
      {(context) => {
        if (context) {
          return (
            <Component
              {...props}
              ref={ref}
              loading={context.loading}
              data={context.data}
              updateSelectedRows={context.updateSelectedRows}
              handleIsBrandedChange={context.handleIsBrandedChange}
            />
          );
        }

        return (<Component {...props} ref={ref} />);
      }}
    </PartnerCampaignContext.Consumer>
  ));

  return WrappedComponent;
};

export default withConsumer;
