/* eslint-disable no-undef */

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

class SignOut extends Component {
  componentDidMount() {
    this.signOut();
    window.location = '/';
  }

  signOut = async () => {
    await Auth.signOut();
  }

  render() {
    return (
      <div />
    );
  }
}

export default SignOut;
