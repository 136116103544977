import React, { Component } from 'react';
import {
  Columns,
  Heading,
  Section,
  s3AssetsJson,
  Typography,

} from '@rlx-pros/react-foundation';
import DocumentCard from './DocumentCard';
import VideoCard from './VideoCard';

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      support: {},
    };
  }

  componentDidMount() {
    s3AssetsJson('content/admin/support').then((data) => this.setState({ support: data }));
  }

  render() {
    const { support } = this.state;
    const { documents, videos } = support;

    return (
      <div>
        <Section>
          <Heading>Videos</Heading>
          <Columns>
            {videos && videos.map((video) => (
              <VideoCard
                key={`video-card-${video.title}`}
                image={video.image}
                title={video.title}
                video={video.video}
              />
            ))}
          </Columns>
          {(!videos || videos.length < 1) && (
            <Typography>There are no videos available at this time</Typography>
          )}
        </Section>
        <Section>
          <Heading>Documents</Heading>
          <Columns>
            {documents && documents.map((document) => (
              <DocumentCard
                key={`document-card-${document.title}`}
                description={document.description}
                document={document.document}
                title={document.title}
                subtitle={document.subtitle}
              />
            ))}
          </Columns>
          {(!documents || documents.length < 1) && (
            <Typography>There are no documents available at this time</Typography>
          )}
        </Section>
      </div>
    );
  }
}

