/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ContentHeader,
  Navtabs,
  NotFound,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Can from 'shared/components/Can';
import Request from 'shared/requests/Request';
import PermissionEnum from 'shared/enum/PermissionEnum';
import { getAuthorizedClientIds } from 'shared/requests/authorizedClient';
import ClientsContext from './clients/ClientsContext';
import Subscriptions from './clients/Subscriptions';

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = async () => {
    const { enqueueSnackbar } = this.props;
    const authorizedClientIds = await getAuthorizedClientIds();

    const authorizedClientId = authorizedClientIds.authorizedAs;
    // const authorizedRetailClientId = authorizedClientIds.retailAuthorizedAs;

    // if (!authorizedClientId) {
    //   enqueueSnackbar('Unable to retrieve the client user for this Admin user. Cannot login as any clients.', { variant: 'warning' });
    // }

    this.setState({
      loading: true,
    });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client`;

    return Request.get(url)
      .then((result) => {
        if (result.data) {
          // We assign this in the state so the table updates when the information changes.
          const clients = result.data.map(({
            ...client
          }) => ({
            ...client,
            isAuthorizedClient: (client.id && client.id === authorizedClientId),
            // isAuthorizedRetailClient: (client.id && client.id === authorizedRetailClientId),
            // aaaAuthorized: aaaAuthorizations.length > 0,
            // spAuthorized: false,
            // mwsAuthorized: mwsAuthorizations.length > 0 && mwsAuthorizations[0].authToken,
            // hasAcceptedContract: client.contract !== null,
          }));
          this.setState({
            clients,
          });
        } else {
          const message = 'Unable to load clients.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, result);
        }
      })
      .catch((error) => {
        const message = 'Unable to load clients.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { clients, loading } = this.state;
    const { match } = this.props;
    const { path, url } = match;

    const links = [
      {
        title: 'Subscriptions',
        href: `${url}/subscriptions`,
      },
    ];

    return (
      <div>
        <Can action={PermissionEnum.LIST_CLIENTS}>
          <ContentHeader title="Clients" size="medium" color="white">
            <Button.Group size="small">
              <Can action={PermissionEnum.CREATE_CLIENTS}>
                <Link to="/clients/create" className="button is-rounded">Create</Link>
              </Can>
            </Button.Group>
          </ContentHeader>
          <Navtabs links={links} />
          <ClientsContext.Provider value={{
            clients,
            loading,
            requestData: this.requestData,
          }}
          >
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${url}/subscriptions`} />
              </Route>
              <Route exact path={`${path}/subscriptions`} component={Subscriptions} />
              <Route component={NotFound} />
            </Switch>
          </ClientsContext.Provider>
        </Can>
      </div>
    );
  }
}

Clients = withSnackbar(Clients);

const mapStateToProps = (state) => ({
  auth: state.app.auth,
});

export default connect(
  mapStateToProps,
)(Clients);
