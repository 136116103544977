import React, { Component } from 'react';
import {
  Columns,
  ContentHeader,
  Section,
} from '@rlx-pros/react-foundation';
// import Can from 'shared/components/Can';
// import PermissionEnum from 'shared/enum/PermissionEnum';
import ApplicationSubscriptions from './dashboard/ApplicationSubscriptions';
import ClientStatus from './dashboard/ClientStatus';
import ClientType from './dashboard/ClientType';

class Dashboard extends Component {
  render() {
    return (
      <div>
        {/* <Can action={PermissionEnum.LIST_DASHBOARD}> */}
        <ContentHeader title="Dashboard" size="medium" color="white" />
        <Section>
          <Columns>
            <Columns.Column size={4} style={{ minWidth: '364px' }}>
              <ApplicationSubscriptions />
            </Columns.Column>
            <Columns.Column size={4} style={{ minWidth: '364px' }}>
              <ClientType />
            </Columns.Column>
            <Columns.Column size={4} style={{ minWidth: '364px' }}>
              <ClientStatus />
            </Columns.Column>
          </Columns>
        </Section>
        {/* </Can> */}
      </div>
    );
  }
}

export default Dashboard;
