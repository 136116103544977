const PermissionEnum = {
  LIST_DASHBOARD: 'listDashboard',
  LIST_APPLICATIONS: 'listApplications',
  UPDATE_APPLICATION: 'updateApplication',
  LIST_APPLICATION_CLIENTS: 'listApplicationClients',
  LIST_CLIENTS: 'listClients',
  CREATE_CLIENTS: 'createClients',
  UPDATE_CLIENTS: 'updateClients',
  DISABLE_CLIENT_APP_ACCESS: 'disableClientAppAccess',
  ENABLE_CLIENT_APP_ACCESS: 'enableClientAppAccess',
  LIST_CLIENT_USERS: 'listClientUsers',
  CREATE_CLIENT_USER: 'createClientUser',
  DELETE_CLIENT_USER: 'deleteClientUser',
  DESCRIBE_CLIENT_USER: 'describeClientUser',
  UPDATE_CLIENT_USER_STATUS: 'updateClientUserStatus',
  RESET_CLIENT_USER_PASSWORD: 'resetClientUserPassword',
  LIST_ADMIN_USERS: 'listAdminUsers',
  CREATE_ADMIN_USERS: 'createAdminUsers',
  DELETE_ADMIN_USERS: 'deleteAdminUsers',
  UPDATE_ADMIN_USERS: 'updateAdminUsers',
  LIST_SUPPORT: 'listSupport',
  AUTHORIZE_CLIENT_ACCESS: 'authorizeClientAccess',
  ASSIGN_USER_ROLE: 'assignUserRole',
  UNASSIGN_USER_ROLE: 'unassignUserRole',
};

export default PermissionEnum;
