import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  ContentHeader,
  Navtabs,
} from '@rlx-pros/react-foundation';
import Admin from './support/Admin';

export default class Support extends Component {
  render() {
    const { match } = this.props;
    const { path, url } = match;

    const links = [
      {
        title: 'Admin',
        href: `${url}/admin`,
      },
    ];

    return (
      <div>
        <ContentHeader title="Support" subtitle="Support Resources" size="medium" color="white" />
        <Navtabs links={links} />

        <Switch>
          <Route exact path={path}>
            <Redirect to={`${url}/admin`} />
          </Route>
          <Route exact path={`${path}/admin`} component={Admin} />
        </Switch>
      </div>
    );
  }
}
