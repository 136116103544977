import React from 'react';
import {
  Card,
  Columns,
  Content,
  Heading,
  Media,
} from '@rlx-pros/react-foundation';

const DocumentCard = ({
  description, document, title, subtitle,
}) => (
  <Columns.Column size={4}>
    <Card>
      <Card.Content>
        <Media>
          <Media.Item>
            <Heading size={4}>{title}</Heading>
            <Heading subtitle size={6}>{subtitle}</Heading>
          </Media.Item>
        </Media>
        <Content>
          <p>{description}</p>
        </Content>
      </Card.Content>
      <Card.Footer>
        <Card.Footer.Item renderAs="a" href={document} target="_blank">Download</Card.Footer.Item>
      </Card.Footer>
    </Card>
  </Columns.Column>
);

export default DocumentCard;
