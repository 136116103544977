import React, { Component } from 'react';
import {
  Box,
  ContentHeader,
  ResponsivePie,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Request from 'shared/requests/Request';
import StatusEnum from 'shared/enum/StatusEnum';

class ClientStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientStatuses: {},
      isLoading: true,
      isMounted: true,
    };
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  requestData = () => {
    this.setState({
      isLoading: true,
    });

    const { enqueueSnackbar } = this.props;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/status`;
    Request.get(url)
      .then((result) => {
        const { isMounted } = this.state;
        if (result.data && isMounted) {
          this.setState({
            clientStatuses: result.data,
          });
        } else {
          const message = 'Client status failed to load';
          enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        }
      })
      .catch((error) => {
        const message = 'An error occurred while loading client status';
        enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { clientStatuses, isLoading } = this.state;

    const pieData = Object.keys(clientStatuses).map((status) => ({
      id: status,
      label: status,
      value: clientStatuses[status],
    }));

    return (
      <Box>
        <ContentHeader title="Client Status" />
        <ResponsivePie
          size={300}
          loading={isLoading}
          data={pieData}
        />
      </Box>
    );
  }
}

ClientStatus = withSnackbar(ClientStatus);

export default ClientStatus;
