import React, { Component } from 'react';
import { Can as FoundationCan } from '@rlx-pros/react-foundation';
import { connect } from 'react-redux';
import { getRolesFromAuth } from 'shared/permission';

class Can extends Component {
  render() {
    const {
      action,
      auth,
      children,
      permissionMap,
    } = this.props;
    const roles = getRolesFromAuth(auth);

    return (
      <FoundationCan
        action={action}
        roles={roles}
        permissionMap={permissionMap}
      >
        {children}
      </FoundationCan>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.app.auth,
  permissionMap: state.app.permissionMap,
});

Can = connect(mapStateToProps, {})(Can);

export default Can;
