import ApplicationSkeleton from 'shared/components/ApplicationSkeleton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Request from 'shared/requests/Request';
import Application from './applications/Application';

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { client } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/client/config`;
    Request.get(url, {
      params: {
        clientId: client.id,
      },
    })
      .then((result) => {
        const applications = result.data.applications.sort((x, y) => {
          const a = x.name.toUpperCase();
          const b = y.name.toUpperCase();

          return (a < b ? -1 : (a > b ? 1 : 0));
        });
        this.setState({
          applications,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Client Applications: ', error);
      });
  }

  render() {
    const { applications, loading } = this.state;

    return (
      <div>
        {loading ? (
          <ApplicationSkeleton />
        ) : (
          <div>
            {applications.map((application) => {
              if (application.status !== 'disabled') {
                return (
                  <Application key={application.id} application={application} />
                );
              }

              return (<div key={application.id} />);
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.app.client,
});

export default connect(
  mapStateToProps,
)(Applications);
