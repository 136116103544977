import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ContentHeader,
  LinearProgress,
  Section,
  Table,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Can from 'shared/components/Can';
import PermissionEnum from 'shared/enum/PermissionEnum';
import Request from 'shared/requests/Request';
import DeleteUserModal from './clients/client/users/DeleteUserModal';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      isDeleteAdminUserModalOpen: false,
      isDeleteAdminUserModalProcessing: false,
      loading: true,
      asyncLoading: false,
    };
  }

  componentDidMount() {
    this.requestData('');
  }

  closeDeleteAdminUserModal = () => {
    this.setState({
      isDeleteAdminUserModalOpen: false,
    });
  };

  requestData = (token) => {
    const { enqueueSnackbar } = this.props;
    this.setState({
      loading: true,
      asyncLoading: true,
    });

    if (!token) {
      this.setState({
        users: [],
      });
    }

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/users`;

    Request.get(url, {
      params: {
        paginationToken: token,
      },
    })
      .then((result) => {
        if (result.data && result.data.PaginationToken && result.data.PaginationToken !== '') {
          this.requestData(result.data.PaginationToken);
        } else {
          this.setState({
            asyncLoading: false,
          });
        }
        const { users } = this.state;
        this.setState({
          users: users.concat(result.data.Users),
        });
      })
      .catch((error) => {
        const message = 'Unable to load users.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleUpdate = (newData, oldData, resolve, reject) => {
    const { enqueueSnackbar } = this.props;
    const { Username, Enabled } = newData;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user-status`;
    const method = Enabled === 'true' ? 'POST' : 'PUT';
    Request({
      method,
      url,
      data: {
        Username,
      },
    })
      .then((result) => {
        const { status } = result;
        if (status === 200) {
          const { users } = this.state;
          const index = users.indexOf(oldData);
          users[index].Enabled = newData.Enabled === 'true';
          this.setState({ users });
          resolve(newData);
        } else {
          const errorMessage = 'Admin User failed to update.';
          enqueueSnackbar(errorMessage, { variant: 'error', persist: false });
          reject();
        }
      })
      .catch((error) => {
        const message = 'An error occurred when enabling/disabling admin user.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
        reject();
      });
  }

  deleteAdminUser = async () => {
    this.setState({
      isDeleteAdminUserModalProcessing: true,
    });
    const { enqueueSnackbar } = this.props;
    const { user } = this.state;
    const { Username } = user;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user`;

    await Request.delete(url, {
      data: {
        Username,
      },
      validateStatus: () => true,
    })
      .then((result) => {
        const { status } = result;
        if (status === 200) {
          enqueueSnackbar('User was successfully deleted for the admin.', {
            variant: 'success',
            persist: false,
          });
          this.requestData('');
        } else {
          const errorMessage = 'Request failed to delete admin user.';
          throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        const message = error ? error.message : 'An error occurred during the admin user deletion.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isDeleteAdminUserModalProcessing: false,
          isDeleteAdminUserModalOpen: false,
          user: null,
        });
      });
  }

  render() {
    const { history } = this.props;
    const {
      users, loading, isDeleteAdminUserModalOpen, isDeleteAdminUserModalProcessing, asyncLoading,
    } = this.state;

    let userData = [];
    if (users) {
      userData = users.sort((a, b) => (a.UserMap.email.toLowerCase() > b.UserMap.email.toLowerCase() ? 1 : -1));
    }

    const columns = [
      { title: 'Username', field: 'Username', hidden: true },
      {
        title: 'Email',
        field: 'UserMap.email',
        customSort: (a, b) => Table.sort.insensitive('UserMap.email', a, b),
        defaultSort: 'asc',
        editable: 'never',
      },
      {
        title: 'Given Name',
        field: 'UserMap.given_name',
        editable: 'never',
        customSort: (a, b) => Table.sort.insensitive('UserMap.given_name', a, b),
      },
      {
        title: 'Family Name',
        field: 'UserMap.family_name',
        editable: 'never',
        customSort: (a, b) => Table.sort.insensitive('UserMap.family_name', a, b),
      },
      { title: 'Status', field: 'UserStatus', editable: 'never' },
      {
        title: 'Enabled',
        field: 'Enabled',
        lookup: { true: 'Yes', false: 'No' },
        editable: 'onUpdate',
        render: (cellValue) => {
          if (typeof cellValue === 'object') {
            return (cellValue.Enabled ? 'Yes' : 'No');
          }

          return cellValue;
        },
      },
    ];

    const linearProgress = 50;
    const variant = 'indeterminate';

    return (
      <div>
        {/* <Can action={PermissionEnum.LIST_ADMIN_USERS}> */}
        <ContentHeader title="Users" subtitle="Manage your users" size="medium" color="white">
          <Button.Group size="small">
            <Can action={PermissionEnum.CREATE_ADMIN_USERS}>
              <Link to="/users/create" className="button is-rounded is-outlined">Create</Link>
            </Can>
          </Button.Group>
        </ContentHeader>
        <Section>
          {asyncLoading ? <LinearProgress variant={variant} style={{ height: '2px' }} value={linearProgress} /> : ''}
          <Table
            title="Users"
            columns={columns}
            data={userData}
            loading={loading}
            options={{
              filtering: true,
              sorting: true,
            }}
            editable={{
              onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                this.handleUpdate(newData, oldData, resolve, reject);
              }),
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.requestData(''),
              },
              {
                icon: 'launch',
                tooltip: 'View User',
                position: 'row',
                onClick: (event, rowData) => {
                  history.push(`/users/${rowData.Username}`);
                },
              },
              {
                icon: 'person_remove',
                tooltip: 'Delete User',
                onClick: (event, user) => {
                  event.preventDefault();
                  this.setState({
                    user,
                    isDeleteAdminUserModalOpen: true,
                  });
                },
              },
            ]}
          />
          {/* <Can action={PermissionEnum.DELETE_ADMIN_USERS}> */}
          <DeleteUserModal
            isOpen={isDeleteAdminUserModalOpen}
            isProcessing={isDeleteAdminUserModalProcessing}
            closeCallback={this.closeDeleteAdminUserModal}
            deleteUserCallback={this.deleteAdminUser}
          />
          {/* </Can> */}
        </Section>
        {/* </Can> */}
      </div>
    );
  }
}

export default withSnackbar(Users);
