import React, { Component } from 'react';
import {
  ContentHeader,
  Icon,
  Section,
} from '@rlx-pros/react-foundation';
import { capitalCase } from 'change-case';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

class RoleTable extends Component {
  render() {
    const { permissionMap, permissions } = this.props;
    const groups = Object.keys(permissionMap);
    const roleData = [];
    const columns = [
      {
        column: <TableCell>Permission</TableCell>,
      },
    ];

    groups.forEach((group) => {
      let groupAccess = 0;
      let role;

      columns.push({
        column: <TableCell className="has-text-centered">{capitalCase(group)}</TableCell>,
      });

      for (let j = 0; j < permissions.length; j += 1) {
        role = permissions[j];
        if (permissionMap[group][0] === '*') {
          groupAccess = 1;
        } else {
          groupAccess = permissionMap[group].includes(role) ? 1 : 0;
        }
        if (roleData[j]) {
          roleData[j].push(groupAccess);
        } else {
          roleData[j] = [j, capitalCase(role), groupAccess];
        }
      }
    });

    return (
      <div>
        <ContentHeader title="Roles & Permissions" />
        <Section className="is-paddingless-top">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((cell) => (
                  cell.column
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData.map((row) => (
                <TableRow key={`${row[0]}`}>
                  {row.map((record, index2) => {
                    if (index2) {
                      return (
                        <TableCell className={(index2 !== 1) ? 'has-text-centered' : ''}>
                          {index2 === 1 ? record : (record === 1 ? <Icon icon="check" /> : '')}
                        </TableCell>
                      );
                    }

                    return null;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissionMap: state.app.permissionMap,
  permissions: state.app.permissions,
});

RoleTable = connect(
  mapStateToProps,
)(RoleTable);

export default RoleTable;
