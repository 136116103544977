import React, { Component } from 'react';
import httpStatusCodes from 'http-status-codes';
import Request from 'shared/requests/Request';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ContentHeader,
  Navtabs,
  NotFound,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { setUser, clearUser } from 'redux/App';
import Can from 'shared/components/Can';
import PermissionEnum from 'shared/enum/PermissionEnum';
import Dashboard from './Dashboard';

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  componentWillUnmount() {
    const { clearUser } = this.props;
    clearUser();
  }

  disableUser = () => {
    this.updateUser('PUT', 'disable');
  }

  enableUser = () => {
    this.updateUser('POST', 'enable');
  }

  updateUser = (method, status) => {
    const { user, enqueueSnackbar } = this.props;

    this.setState({ updating: true });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user-status`;
    Request({
      method,
      url,
      data: {
        Username: user.Username,
      },
    })
      .then(() => {
        this.getUser();
      })
      .catch((error) => {
        const message = `Unable to ${status} user.`;
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({ updating: false });
      });
  }

  getUser = () => {
    const { match, setUser, enqueueSnackbar } = this.props;
    const { username } = match.params;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user`;

    this.setState({ loading: true });

    Request.get(url, {
      params: {
        Username: username,
      },
    })
      .then((result) => {
        if (result.data.status !== httpStatusCodes.OK) {
          enqueueSnackbar(result.data.message, { variant: 'error', persist: true });
          console.error(result.data.message);
        } else if (result.data.data) {
          setUser(result.data.data);
        }
      })
      .catch((error) => {
        const message = 'Unable to load user.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { user, match } = this.props;
    const { updating, loading } = this.state;
    const { path, url } = match;

    const name = user.Username ? `${user.UserMap.given_name} ${user.UserMap.family_name}` : '';

    const links = [
      {
        title: 'Dashboard',
        href: `${url}/dashboard`,
      },
    ];

    return (
      <div>
        <Can action={PermissionEnum.LIST_ADMIN_USERS}>
          <ContentHeader title={name || ''} subtitle={user.Username || ''} size="medium" color="white">
            <Can action={PermissionEnum.UPDATE_ADMIN_USERS}>
              <Button.Group size="small">
                { user.Enabled ? (
                  <Button color="danger" outlined rounded loading={updating} disabled={loading} onClick={this.disableUser}>Disable</Button>
                ) : (
                  <Button rounded loading={updating} disabled={loading} onClick={this.enableUser}>Enable</Button>
                )}
              </Button.Group>
            </Can>
          </ContentHeader>
          <Navtabs links={links} />

          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/dashboard`} />
            </Route>
            <Route exact path={`${path}/dashboard`} render={(props) => <Dashboard {...props} loading={loading} getUser={this.getUser} />} />
            <Route component={NotFound} />
          </Switch>
        </Can>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  clearUser: () => dispatch(clearUser()),
});

User = withSnackbar(User);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(User);
