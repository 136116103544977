import React, { Component } from 'react';
import {
  Card,
  Columns,
  Heading,
  Media,
} from '@rlx-pros/react-foundation';
import VideoModal from './videocard/VideoModal';

export default class VideoCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  openModal = () => {
    this.setState({
      open: true,
    });
  }

  closeModal = () => {
    this.setState({
      open: false,
    });
  }

  render() {
    const { image, title, video } = this.props;
    const { open } = this.state;

    return (
      <Columns.Column size={3}>
        <Card onClick={this.openModal} style={{ cursor: 'pointer' }}>
          <Card.Image src={image} />
          <Card.Content>
            <Media>
              <Media.Item>
                <Heading size={4}>{title}</Heading>
              </Media.Item>
            </Media>
          </Card.Content>
        </Card>
        <VideoModal
          open={open}
          onClose={this.closeModal}
          title={title}
          video={video}
        />
      </Columns.Column>
    );
  }
}

