import React from 'react';
import ClientsContext from './ClientsContext';

const withClients = (Component) => {
  const WrappedComponent = React.forwardRef((props, ref) => (
    <ClientsContext.Consumer>
      {(context) => {
        if (context) {
          return (
            <Component
              {...props}
              ref={ref}
              clients={context.clients}
              isLoading={context.loading}
              requestData={context.requestData}
            />
          );
        }

        return (<Component {...props} ref={ref} />);
      }}
    </ClientsContext.Consumer>
  ));

  return WrappedComponent;
};

export default withClients;
