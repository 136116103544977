import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Modal,
} from '@rlx-pros/react-foundation';

const VideoModal = ({
  onClose, open, title, video,
}) => (
  <Modal open={open} contentLabel={title} light dynamic onClose={onClose}>
    <ReactPlayer url={video} />
  </Modal>
);

export default VideoModal;
