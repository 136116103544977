import React, { Component } from 'react';
import { Button, Modal } from '@rlx-pros/react-foundation';

class DeleteUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isProcessing: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.isOpen,
      isProcessing: nextProps.isProcessing,
    };
  }

  close = () => {
    const { closeCallback } = this.props;
    closeCallback();
  }

  deleteUser = () => {
    const { deleteUserCallback } = this.props;
    deleteUserCallback();
  }

  render() {
    const { isOpen, isProcessing } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        contentLabel="Delete User?"
      >
        <p>This action will delete this user. Are you sure this is what you want to do?</p>
        <br />
        <Button.Group position="right">
          <Button outlined disabled={isProcessing} onClick={this.close}>Cancel</Button>
          <Button color="danger" loading={isProcessing} onClick={this.deleteUser}>Delete</Button>
        </Button.Group>
      </Modal>
    );
  }
}

export default DeleteUserModal;
