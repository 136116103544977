import React, { Component } from 'react';
import { Headerbar as FoundationHeaderbar } from '@rlx-pros/react-foundation';
import { connect } from 'react-redux';
import PermissionEnum from 'shared/enum/PermissionEnum';
import { userCanAccess } from 'shared/permission';

class Headerbar extends Component {
  accountLinks = [
    {
      title: 'Sign Out',
      href: '/sign-out',
    },
  ];

  permittedLinks = () => {
    const links = [];

    if (userCanAccess(PermissionEnum.LIST_DASHBOARD)) {
      links.push({
        title: 'Dashboard',
        href: '/dashboard',
      });
    }

    if (userCanAccess(PermissionEnum.LIST_CLIENTS)) {
      links.push({
        title: 'Clients',
        href: '/clients',
      });
    }

    if (userCanAccess(PermissionEnum.LIST_APPLICATIONS)) {
      links.push({
        title: 'Applications',
        href: '/applications',
      });
    }

    if (userCanAccess(PermissionEnum.LIST_ADMIN_USERS)) {
      links.push({
        title: 'Users',
        href: '/users',
        exact: false,
      });
    }

    if (userCanAccess(PermissionEnum.LIST_SUPPORT)) {
      links.push({
        title: 'Support',
        href: '/support',
        exact: false,
      });
    }

    return links;
  };

  render() {
    const { auth } = this.props;

    return (
      <FoundationHeaderbar>
        <FoundationHeaderbar.Spacer />
        <FoundationHeaderbar.Links
          links={this.permittedLinks()}
          noApplications
        />
        <FoundationHeaderbar.Account
          links={this.accountLinks}
          company={auth.attributes ? 'Laura Parrish' : '-'}
          username={auth.username || '-'}
          hideSupport
        />
      </FoundationHeaderbar>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.app.auth,
});

export default connect(
  mapStateToProps,
)(Headerbar);
