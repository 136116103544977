import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import {
  ContentHeader,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import ApplicationSkeleton from 'shared/components/ApplicationSkeleton';
import Application from './applications/Application';

class Applications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { enqueueSnackbar } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/application`;

    Request.get(url)
      .then((result) => {
        const data = result.data.applications.sort((x, y) => {
          const a = x.name.toUpperCase();
          const b = y.name.toUpperCase();

          return (a < b ? -1 : (a > b ? 1 : 0));
        });
        this.setState({
          applications: data,
          loading: false,
        });
      })
      .catch((error) => {
        const message = 'Unable to load applications.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      });
  }

  render() {
    const { applications, loading } = this.state;

    return (
      <div>
        <ContentHeader title="Applications" size="medium" color="white" />

        {loading ? (
          <ApplicationSkeleton />
        ) : (
          <div>
            {applications.map((application) => {
              if (application.status !== 'disabled') {
                return (
                  <Application
                    key={application.id}
                    application={application}
                    form={`application-edit-${application.name}`}
                    initialValues={{ description: application.description }}
                  />
                );
              }

              return (<div key={application.id} />);
            })}
          </div>
        )}
      </div>
    );
  }
}

Applications = withSnackbar(Applications);

export default Applications;
