import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { required } from 'shared/fieldValidation';
import { setUser } from 'redux/Wizard';
import {
  Button,
  Drawer,
  Level,
  MenuItem,
  ReduxSelect,
  ReduxTextField,
  Section,
} from '@rlx-pros/react-foundation';
import RoleTable from '../dashboard/RoleTable';

class StepUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRoleTableDrawerOpen: false,
    };
  }

  next = (data) => {
    const { nextStep, setUser } = this.props;
    setUser(data);
    nextStep();
  }

  handleRoleTable = (event) => {
    event.preventDefault();
    this.toggleRoleTableDrawer();
  }

  toggleRoleTableDrawer = () => {
    const { isRoleTableDrawerOpen } = this.state;

    this.setState({
      isRoleTableDrawerOpen: !isRoleTableDrawerOpen,
    });
  }

  render() {
    const { handleSubmit, permissionMap } = this.props;
    const { isRoleTableDrawerOpen } = this.state;

    const roles = Object.keys(permissionMap);
    const userRoles = [];
    roles.forEach((obj, index) => {
      userRoles[index] = { key: `admin:${obj}`, value: `admin:${obj}` };
    });

    return (
      <div>
        <Section>
          <form id="create-user" onSubmit={handleSubmit(this.next)}>
            {/* Add back non Admin E-mail validation */}
            <ReduxField
              name="email"
              label="Email"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
            <ReduxField
              name="first_name"
              label="First Name"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
            <ReduxField
              name="last_name"
              label="Last Name"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
            />
            <div style={{ margin: '16px 0 8px 0' }}>
              <ReduxField
                name="groupName"
                label="User Role"
                component={ReduxSelect}
                className="MuiFormControl-marginNormal"
                fullWidth
                helperText={
                  (
                    <span>
                      <a href="/" onClick={this.handleRoleTable}>Read more </a>
                      about role permissions
                    </span>
                  )
                }
              >
                {userRoles.map((role) => (
                  <MenuItem key={role.key} value={role.value}>
                    {role.value}
                  </MenuItem>
                ))}
              </ReduxField>
            </div>
          </form>
        </Section>

        <Section>
          <Level>
            <Level.Side align="left" />
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="create-user">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>

        <Drawer
          anchor="right"
          open={isRoleTableDrawerOpen}
          onClose={this.toggleRoleTableDrawer}
        >
          <Drawer.Bar close={this.toggleRoleTableDrawer} />
          <RoleTable
            toggleRoleTableDrawer={this.toggleRoleTableDrawer}
          />
        </Drawer>
      </div>
    );
  }
}

const getUser = async (email) => {
  const url = `${process.env.REACT_APP_ORCHESTRATOR}/admin/user-status`;
  const response = await Request.get(url, {
    params: {
      Username: email,
    },
  });

  return response.data;
};

const asyncValidate = (values) => getUser(values.email)
  .then((data) => {
    if (data.available === false) {
      const error = { email: 'That email is taken' };

      throw error;
    }
  });

StepUser = reduxForm({
  form: 'create-user',
  asyncValidate,
  asyncBlurFields: ['email'],
  enableReinitialize: true,
})(StepUser);

const mapStateToProps = (state) => ({
  user: state.wizard.user,
  initialValues: state.wizard.user,
  permissionMap: state.app.permissionMap,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
});

StepUser = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepUser);

export default StepUser;
