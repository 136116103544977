import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { setClient } from 'redux/Wizard';
import { required } from 'shared/fieldValidation';
import {
  Button,
  Level,
  MenuItem,
  Section,
  ReduxSelect,
} from '@rlx-pros/react-foundation';
import ClientTypeEnum from 'shared/enum/ClientTypeEnum';

class StepPartnerType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientType: '',
    };
  }

  next = () => {
    const { clientType } = this.state;
    const { updateClientType } = this.props;
    updateClientType(clientType);
  }

  onChange = (e) => {
    this.setState({ clientType: e.target.value });
  }

  render() {
    const { handleSubmit } = this.props;
    const clientTypes = [
      {
        label: ClientTypeEnum.AGENCY,
        value: 'agency',
      },
      {
        label: ClientTypeEnum.MANAGED_SERVICE,
        value: 'managed service',
      },
      {
        label: ClientTypeEnum.SAAS,
        value: 'SaaS',
      },
    ];

    return (
      <div>
        <Section>
          <form id="onboarding-client" onSubmit={handleSubmit(this.next)}>
            <ReduxField
              id="client_type"
              name="type"
              label="Client Type"
              component={ReduxSelect}
              validate={[required]}
              options={clientTypes}
              fullWidth
              onChange={this.onChange}
            >
              {clientTypes.map((type) => (
                <MenuItem key={type.label} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </ReduxField>
          </form>
        </Section>

        <Section>
          <Level>
            <Level.Side align="left" />
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-client">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setClient: (client) => dispatch(setClient(client)),
});

export default connect(
  null,
  mapDispatchToProps,
)(reduxForm({
  form: 'onboarding-client',
})(StepPartnerType));
