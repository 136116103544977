import ClientTypeEnum from 'shared/enum/ClientTypeEnum';
import React from 'react';
import { Heading } from '@rlx-pros/react-foundation';

const StepNavigation = (props) => {
  const { clientType, currentStep } = props;

  function step(number, title) {
    return {
      number,
      title,
      state: (currentStep === number ? 'is-active' : (currentStep > number ? 'is-completed' : '')),
    };
  }

  const retailClientSteps = [
    step(1, 'Partner Details'),
    step(2, 'Users'),
    step(3, 'Partner Skus'),
    step(4, 'Partner Campaigns'),
    step(5, 'Review'),
  ];

  const agencyClientSteps = [
    step(1, 'Client'),
    step(2, 'Users'),
    step(3, 'Applications'),
    step(4, 'Review'),
  ];

  const managedServiceClientSteps = [
    step(1, 'Client'),
    step(2, 'Users'),
    step(3, 'Applications'),
    step(4, 'Review'),
  ];

  const saasServiceClientSteps = [
    step(1, 'Client'),
    step(2, 'Users'),
    step(3, 'Applications'),
    step(4, 'Review'),
  ];

  const defaultClientSteps = [
    step(1, 'Client'),
    step(2, 'Users'),
    step(3, 'Applications'),
    step(4, 'Review'),
  ];

  let steps = [];

  switch (clientType) {
    case ClientTypeEnum.AGENCY.toLocaleLowerCase(): {
      steps = agencyClientSteps;
      break;
    }
    case ClientTypeEnum.RETAIL.toLocaleLowerCase(): {
      steps = retailClientSteps;
      break;
    }
    case ClientTypeEnum.MANAGED_SERVICE.toLocaleLowerCase(): {
      steps = managedServiceClientSteps;
      break;
    }
    case ClientTypeEnum.SAAS.toLocaleLowerCase(): {
      steps = saasServiceClientSteps;
      break;
    }
    default: {
      steps = defaultClientSteps;
      break;
    }
  }

  return (
    <div className="steps">
      {steps.map((step, index) => {
        const key = `key-${index}`;

        return (
          <div key={key} className={`step-item is-info ${step.state}`}>
            <div className="step-marker">
              { step.number }
            </div>
            <div className="step-details">
              <Heading size={6}>
                { step.title }
              </Heading>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepNavigation;
