import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import MarketplaceEnum from 'shared/enum/MarketplaceEnum';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Box,
  Button,
  Grid,
  Level,
  Section,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import PartnerSkusTable from './tables/PartnerSkusTable';

class StepPartnerSkus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unAddedData: [],
      addedData: [],
      selectedUnAddedRows: [],
      selectedAddedRows: [],
      loading: false,
      loadedPrefixes: [],
    };

    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidUpdate() {
    this.requestData();
  }

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  }

  next = () => {
    const { nextStep } = this.props;
    nextStep();
  }

  updateUnaddedRowsState = (selectedRows) => {
    this.setState({ selectedUnAddedRows: selectedRows });
  }

  updateAddedRowsState = (selectedRows) => {
    this.setState({ selectedAddedRows: selectedRows });
  }

  /* eslint-disable no-param-reassign */
  /* eslint-disable no-return-assign */
  add() {
    let { unAddedData, addedData } = this.state;
    const { selectedUnAddedRows } = this.state;
    const { updatePartnerSkus } = this.props;

    const unAdded = unAddedData.filter((eachUnaddedData) => {
      const selectedRow = selectedUnAddedRows.find((eachSelectedUnaddedRow) => eachSelectedUnaddedRow.sku === eachUnaddedData.sku);
      return !selectedRow;
    });
    let added = unAddedData.filter((eachUnaddedData) => {
      const selectedRow = selectedUnAddedRows.find((eachSelectedUnaddedRow) => eachSelectedUnaddedRow.sku === eachUnaddedData.sku);
      if (selectedRow) {
        selectedRow.checked = false;
      }
      return selectedRow;
    });
    added = added.concat(addedData);
    added.sort((a, b) => ((a.sku > b.sku) ? 1 : ((b.sku > a.sku) ? -1 : 0)));
    unAddedData = unAddedData.map((eachUnAddedData) => eachUnAddedData.tableData.checked = false);
    addedData = addedData.map((eachAddedData) => eachAddedData.tableData.checked = false);

    this.setState({ unAddedData: unAdded, addedData: added, selectedUnAddedRows: [] });
    updatePartnerSkus(added);
  }

  remove() {
    let { unAddedData, addedData } = this.state;
    const { selectedAddedRows } = this.state;
    const { updatePartnerSkus } = this.props;

    const added = addedData.filter((eachAddedData) => {
      const selectedRow = selectedAddedRows.find((eachSelectedAddedRow) => eachSelectedAddedRow.sku === eachAddedData.sku);
      return !selectedRow;
    });
    let unAdded = addedData.filter((eachAddedData) => {
      const selectedRow = selectedAddedRows.find((eachSelectedAddedRow) => eachSelectedAddedRow.sku === eachAddedData.sku);
      if (selectedRow) {
        selectedRow.checked = false;
      }
      return selectedRow;
    });
    unAdded = unAdded.concat(unAddedData);
    unAdded.sort((a, b) => ((a.sku > b.sku) ? 1 : ((b.sku > a.sku) ? -1 : 0)));
    unAddedData = unAddedData.map((eachUnAddedData) => eachUnAddedData.tableData.checked = false);
    addedData = addedData.map((eachAddedData) => eachAddedData.tableData.checked = false);

    this.setState({ unAddedData: unAdded, addedData: added, selectedAddedRows: [] });
    updatePartnerSkus(added);
  }

  requestData() {
    const { prefixes, enqueueSnackbar } = this.props;
    const { loadedPrefixes, addedData } = this.state;
    if (prefixes === loadedPrefixes) return;
    this.setState({ loading: true, loadedPrefixes: prefixes });

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/collection-service/skus-by-prefix`;
    /* eslint-disable consistent-return */
    /* eslint-disable array-callback-return */
    const partnerPrefixes = prefixes.map((eachPrefix) => ({ prefix: eachPrefix.prefix, marketplaceId: MarketplaceEnum.US }));
    /* eslint-disable consistent-return */
    /* eslint-disable array-callback-return */

    Request.get(url, { params: partnerPrefixes })
      .then((result) => {
        if (result.data) {
          const unaddedData = result.data.filter((eachData) => !addedData.find((eachAddedData) => eachAddedData.sku === eachData.sku));
          this.setState({ unAddedData: unaddedData });
        }
      })
      .catch((error) => {
        const message = 'Unable to load skus.';
        enqueueSnackbar(message, { variant: 'error', persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {
      unAddedData,
      addedData,
      loading,
      selectedUnAddedRows,
      selectedAddedRows,
    } = this.state;
    const { handleSubmit } = this.props;

    return (
      <div>
        <form id="onboarding-partner-skus" onSubmit={handleSubmit(this.next)}>
          <Grid container>
            <Grid item xs={5}>
              <Box>
                <PartnerSkusTable
                  loading={loading}
                  data={unAddedData}
                  updateSelectedRows={this.updateUnaddedRowsState}
                />
              </Box>
              <Section>
                <div style={{ float: 'right' }}>
                  <Button type="button" outlined color={!selectedUnAddedRows.length ? '' : 'primary'} onClick={this.add} disabled={!selectedUnAddedRows.length}>Add</Button>
                </div>
              </Section>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Box>
                <PartnerSkusTable
                  loading={loading}
                  data={addedData}
                  updateSelectedRows={this.updateAddedRowsState}
                />
              </Box>
              <Section>
                <div style={{ float: 'right' }}>
                  <Button type="button" outlined color={!selectedAddedRows.length ? '' : 'primary'} onClick={this.remove} disabled={!selectedAddedRows.length}>Remove</Button>
                </div>
              </Section>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </form>
        <Section>
          <Level>
            <Level.Side align="left">
              <Level.Item>
                <Button onClick={this.previous}>Back</Button>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-partner-skus">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applications: state.wizard.applications,
  client: state.wizard.client,
  users: state.wizard.users,
});

StepPartnerSkus = withSnackbar(StepPartnerSkus);

export default connect(
  mapStateToProps,
  null,
)(reduxForm({
  form: 'onboarding-partner-skus',
})(StepPartnerSkus));
