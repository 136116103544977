import React, { Component, createRef } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Table, Checkbox } from '@rlx-pros/react-foundation';
import AbbreviatedCampaignTypeEnum from 'shared/enum/AbbreviatedCampaignTypeEnum';
import withConsumer from '../withConsumer';

class PartnerCampaignsTable extends Component {
  constructor(props) {
    super(props);

    this.tableRef = createRef();
    const filters = queryString.parse(props.location.search);

    let columns = [
      {
        title: 'Campaign Id',
        field: 'campaignId',
        editable: 'never',
        hidden: true,
      },
      {
        title: 'Campaign Name',
        field: 'name',
        editable: 'never',
      },
      {
        title: 'State',
        field: 'state',
        editable: 'never',
      },
      {
        title: 'Start Date',
        field: 'startDate',
        editable: 'never',
      },
      {
        title: 'Type',
        field: 'campaignTypeDisplay',
        editable: 'never',
        lookup: {
          sponsoredBrands: [AbbreviatedCampaignTypeEnum.sponsoredBrands],
          sponsoredProducts: [AbbreviatedCampaignTypeEnum.sponsoredProducts],
          sponsoredBrandsVideo: [AbbreviatedCampaignTypeEnum.sponsoredBrandsVideo],
        },
        customSort: (a, b) => Table.sort.insensitive('campaignTypeDisplay', a, b),
        render: (rowData) => AbbreviatedCampaignTypeEnum[rowData.campaignTypeDisplay],
      },
      {
        title: 'Branded',
        field: 'isBranded',
        type: 'boolean',
        render: (cellValue) => <Checkbox checked={cellValue.isBranded} onChange={() => this.handleChange(cellValue.campaignId, cellValue.isBranded)} />,
      },
    ];

    columns = columns.map((column) => {
      if (filters[column.field] && !column.lookup) {
        // eslint-disable-next-line no-param-reassign
        column.defaultFilter = filters[column.field];
      }

      return column;
    });

    this.state = {
      selectedRows: [],
      columns,
    };
  }

  handleChange = (campaignId, isBranded) => {
    const { handleIsBrandedChange } = this.props;
    handleIsBrandedChange(campaignId, isBranded);
  }

  render() {
    const { columns } = this.state;
    const {
      loading,
      data,
      updateSelectedRows,
    } = this.props;

    return (
      <Table
        tableRef={this.tableRef}
        borderless
        loading={loading}
        columns={columns}
        data={data}
        onSelectionChange={(selectedRows) => {
          this.setState({ selectedRows });
          updateSelectedRows(selectedRows);
        }}
        onFilterChange={() => {
          const { selectedRows } = this.state;

          // deselect all
          for (let i = 0; i < selectedRows.length; i += 1) {
            const { tableData } = selectedRows[i];
            if (tableData.checked) {
              tableData.checked = false;
            }
          }

          this.tableRef.current.dataManager.selectedCount = 0;
          this.tableRef.current.setState({ selectedCount: 0 });
          this.setState({ selectedRows: [] });
          updateSelectedRows(selectedRows);
        }}
        options={{
          filtering: true,
          grouping: false,
          search: false,
          draggable: false,
          selection: true,
          sorting: true,
          toolbar: true,
          showTitle: false,
        }}
      />
    );
  }
}

PartnerCampaignsTable = withRouter(PartnerCampaignsTable);
PartnerCampaignsTable = withConsumer(PartnerCampaignsTable);

export default PartnerCampaignsTable;
