import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { setClient } from 'redux/Wizard';
import {
  Button,
  AutoComplete,
  Level,
  ReduxTextField,
  Section,
  s3File,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import {
  required,
} from 'shared/fieldValidation';

class StepPartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerPrefixes: [],
      loading: false,
    };
  }

  componentDidMount() {
    const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
    this.setState({ loading: true });
    s3File(appEnvironment, 'content/admin/partnerPrefixes.txt').then((data) => {
      let arr = data.split('\n');
      /* eslint-disable radix */
      arr.sort((a, b) => parseInt(a) - parseInt(b));
      /* eslint-disable radix */
      arr = arr.map((eachPrefix) => ({ prefix: eachPrefix, label: eachPrefix, selected: false }));
      this.setState({ partnerPrefixes: arr, loading: false });
    });
  }

  next = (data) => {
    const { partnerName } = data;
    const {
      nextStep, enqueueSnackbar, updatePrefixes, updatePartnerName,
    } = this.props;
    const { partnerPrefixes } = this.state;
    const selectedPartnerPrefixes = partnerPrefixes.filter((eachPrefix) => eachPrefix.selected);
    if (!selectedPartnerPrefixes || selectedPartnerPrefixes.length === 0) {
      enqueueSnackbar('At least one partner prefix must be selected', { variant: 'error', persist: true });
      return;
    }
    updatePartnerName(partnerName);
    updatePrefixes(selectedPartnerPrefixes);
    nextStep();
  }

  onCheckboxChange = (event, values) => {
    /* eslint-disable no-param-reassign */
    const { partnerPrefixes } = this.state;
    partnerPrefixes.forEach((eachPrefix) => {
      if (eachPrefix.prefix === event.target.value) {
        eachPrefix.selected = event.target.checked;
      }
      if (!event.target.value) {
        eachPrefix.selected = false;
        const foundValue = values.filter((eachValue) => eachValue.prefix === eachPrefix.prefix);
        if (foundValue && foundValue.length > 0) {
          eachPrefix.selected = true;
        }
      }
    });
    /* eslint-enable no-multi-spaces */
    this.setState({
      partnerPrefixes,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { partnerPrefixes, loading } = this.state;
    return (
      <div>
        <Section>
          <form id="onboarding-partners" onSubmit={handleSubmit(this.next)}>
            <div>
              {loading ? <Skeleton height={56} width={250} />
                : (
                  <AutoComplete
                    multiple
                    options={partnerPrefixes}
                    disableCloseOnSelect
                    style={{ width: 500 }}
                    label="Partner Prefixes"
                    onChange={this.onCheckboxChange}
                  />
                ) }
              <ReduxField
                name="partnerName"
                label="Partner Name"
                component={ReduxTextField}
                validate={[required]}
                margin="normal"
                fullWidth
              />
            </div>
          </form>
        </Section>

        <Section>
          <Level>
            <Level.Side align="left" />
            <Level.Side align="right">
              <Level.Item>
                <Button color="info" form="onboarding-partners">Next</Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setClient: (client) => dispatch(setClient(client)),
});

StepPartnerDetails = withSnackbar(StepPartnerDetails);

export default connect(
  null,
  mapDispatchToProps,
)(reduxForm({
  form: 'onboarding-partners',
})(StepPartnerDetails));
