import React, { Component } from 'react';
import Request from 'shared/requests/Request';
import { Field as ReduxField, reduxForm, initialize } from 'redux-form';
import { required } from 'shared/fieldValidation';
import {
  Box,
  Button,
  ContentHeader,
  Section,
  ReduxTextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import PermissionEnum from 'shared/enum/PermissionEnum';
import Can from 'shared/components/Can';
import { userCanAccess } from 'shared/permission';

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      application: props.application,
    };
  }

  submit = (data) => {
    const promise = new Promise(() => {
      const { enqueueSnackbar } = this.props;
      const { application } = this.state;
      const { description } = data;

      const url = `${process.env.REACT_APP_ORCHESTRATOR}/application`;
      Request.put(url, {
        applicationId: application.id,
        description,
      })
        .then(() => {
          const { dispatch, form } = this.props;
          dispatch(initialize(form, { description }));
        })
        .catch((error) => {
          const message = 'Unable to save application.';
          enqueueSnackbar(message, { variant: 'error', persist: true });
          console.error(message, error);
        });
    });

    return promise;
  }

  render() {
    const userCanEdit = userCanAccess(PermissionEnum.UPDATE_APPLICATION);
    const { application } = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
    } = this.props;

    return (
      <Section>
        <Box>
          <ContentHeader title={application.name} subtitle={application.id}>
            <Button.Group size="small">
              <Can action={PermissionEnum.UPDATE_APPLICATION}>
                <Button
                  onClick={reset}
                  disabled={submitting || pristine}
                >
                  Cancel
                </Button>
                <Button
                  color="info"
                  form={`form-${application.id}`}
                  disabled={pristine}
                  loading={submitting}
                >
                  Save
                </Button>
              </Can>
            </Button.Group>
          </ContentHeader>

          <form id={`form-${application.id}`} onSubmit={handleSubmit(this.submit)}>
            <ReduxField
              name="description"
              component={ReduxTextField}
              validate={[required]}
              margin="normal"
              fullWidth
              multiline
              rowsMax="10"
              disabled={!userCanEdit}
            />
          </form>
        </Box>
      </Section>
    );
  }
}

Application = withSnackbar(Application);

Application = reduxForm({})(Application);

export default Application;
